import React, { useState, useEffect } from 'react';
import dateformat from 'utils/dateformat';
import { Map, Filter } from './components';
import Reload from '../../components/Reload/Reload';
import ApiGServer from 'services/ApiGServer';
import ApiMServer from 'services/ApiMServer';
import Api from 'services/Api';

const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

const Maps = () => {
  const user = JSON.parse(localStorage.getItem('userData'));
  if (user?.permission?.maps?.read === false) {
    window.location.replace('/home');
  }

  const [zoom] = useState(5);
  const [allPoints, setAllPoints] = useState([]);
  const [points, setPoints] = useState([]);
  const [mapPoints, setMapPoints] = useState(false);
  const [center] = useState({ lat: -14.8227361, lng: -54.1549677 });
  const [companyNames, setCompanyNames] = useState([]);
  const [state, setState] = useState([])
  const [city, setCity] = useState([])
  const [companies, setCompanies] = useState([]);

  let [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      let { data: equipments } = await ApiGServer.get('/equipment/all/filtered');
      let locatedList = [].concat.apply([], equipments.filter((e) => {
        return e.mode === 'RENTAL';
      }).map((i) => i.serialnumber));

      const response1 = await Api.get(`/connection/status`);
      const connectedEquips = response1.data;
      const connected = connectedEquips.map(item => item.serialnumber);

      const result = [];
      const pins = equipments.map(async (equipment) => {
        let lat = Number(parseFloat(equipment.lat).toFixed(7));
        let lng = Number(parseFloat(equipment.lng).toFixed(7));
        let date = dateformat(equipment.updatedAt);
        let serial = equipment.serialnumber;
        let versaosw = equipment.versaosw;
        let versaofwpri = equipment.versaofwpri;
        let versaofwosc = equipment.versaofwosc;
        let located = locatedList.includes(serial);
        let isConnected = connected.includes(equipment.serialnumber);
        let address = ''
        if (equipment.address) {
          address = equipment.address;
        }

        if (lat === 0 || lng === 0) return;

        if (!isNaN(lat) && !isNaN(lng)) {
          result.push({ serial, lat, lng, date, versaosw, versaofwpri, versaofwosc, located, isConnected, address });
        }
      })
      await Promise.all(pins);
      var points = result.map(item => ({
        located: item.located,
        type: "Feature",
        properties: {
          cluster: false,
          id: item.serial,
          data: item
        },
        geometry: {
          type: "Point",
          coordinates: [
            parseFloat(item.lng),
            parseFloat(item.lat)
          ]
        },
        address: item.address
      }))
      points = points.sort(function (a, b) {
        if (a.properties.data.serial > b.properties.data.stitleerial) {
          return 1;
        }
        if (a.properties.data.serial < b.properties.data.serial) {
          return -1;
        }
        return 0;
      })
      setPoints(points);
      setAllPoints(points);
      setLoading(false);

      const results = [];
      const pinss = equipments.filter(i => i.address && i.address.state)
        .map(async (equipment) => {
          let lat = Number(parseFloat(equipment.lat).toFixed(8));
          let lng = Number(parseFloat(equipment.lng).toFixed(8));
          let date = dateformat(equipment.updatedAt);
          let serial = equipment.serialnumber;
          let versaosw = equipment.versaosw;
          let versaofwpri = equipment.versaofwpri;
          let versaofwosc = equipment.versaofwosc;
          let located = locatedList.includes(serial);

          if (equipment.address !== "" && equipment.address.state !== null) {
            lat = Number(parseFloat(equipment.address.state.lat).toFixed(8));
            lng = Number(parseFloat(equipment.address.state.lng).toFixed(8));
          }
          if (lat === 0 || lng === 0) { return }
          if (!isNaN(lat) && !isNaN(lng))
            results.push({ serial, lat, lng, date, versaosw, versaofwpri, versaofwosc, located });
        });
      await Promise.all(pinss);

      points = results.map(item => ({
        located: item.located,
        type: "Feature",
        properties: {
          cluster: false,
          id: item.serial,
          data: item
        },
        geometry: {
          type: "Point",
          coordinates: [
            parseFloat(item.lng),
            parseFloat(item.lat)
          ]
        },
        address: item.address
      }));

      points = points.sort(function (a, b) {
        if (a.properties.data.serial > b.properties.data.stitleerial) {
          return 1;
        }
        if (a.properties.data.serial < b.properties.data.serial) {
          return -1;
        }
        return 0;
      });
      setState(points);

      const resultss = [];
      const pinsss = equipments.filter(i => i.address && i.address.city)
        .map(async (equipment) => {
          let lat = Number(parseFloat(equipment.lat).toFixed(7));
          let lng = Number(parseFloat(equipment.lng).toFixed(7));
          let date = dateformat(equipment.updatedAt);
          let serial = equipment.serialnumber;
          let versaosw = equipment.versaosw;
          let versaofwpri = equipment.versaofwpri;
          let versaofwosc = equipment.versaofwosc;
          let located = locatedList.includes(serial);

          if (equipment.address !== "" && equipment.address.city !== null) {
            lat = Number(parseFloat(equipment.address.city.lat).toFixed(7));
            lng = Number(parseFloat(equipment.address.city.lng).toFixed(7));
          }
          if (lat === 0 || lng === 0) { return }
          if (!isNaN(lat) && !isNaN(lng))
            resultss.push({ serial, lat, lng, date, versaosw, versaofwpri, versaofwosc, located });
        });
      await Promise.all(pinsss);

      points = resultss.map(item => ({
        located: item.located,
        type: "Feature",
        properties: {
          cluster: false,
          id: item.serial,
          data: item
        },
        geometry: {
          type: "Point",
          coordinates: [
            parseFloat(item.lng),
            parseFloat(item.lat)
          ]
        },
        address: item.address
      }));

      points = points.sort(function (a, b) {
        if (a.properties.data.serial > b.properties.data.stitleerial) {
          return 1;
        }
        if (a.properties.data.serial < b.properties.data.serial) {
          return -1;
        }
        return 0;
      });

      setCity(points);
    })();
  }, []);


  useEffect(() => {
    (async () => {
      const { data } = await ApiMServer.get('/company/v1/getcooperative');
      setCompanies(data);
      let filterCompany = data.filter((item) => {
        return item.typeCompany === true;
      })
      let cNames = [].concat.apply([], filterCompany.map((company) => {
        return company.name;
      }));
      setCompanyNames(cNames.sort());
    })();
  }, []);

  const filter = (filters, company, serial) => {
    // Na estrutura atual do Filter, filters é um array:
    // [0]: TODOS, [1]: LOCAÇÃO, [2]: SIMT, [3]: BIMT, [4]: CPMT,
    // [5]: EEMT, [6]: ESMR, [7]: ESTADOS, [8]: CIDADES, [9]: ONLINE, [10]: 999DRY
  
    // Determina qual é a fonte dos pontos (estado, cidade ou normal)
    let sourcePoints;
    if (filters[7]) { // ESTADOS
      sourcePoints = [...state];
      setMapPoints(true);
    } else if (filters[8]) { // CIDADES
      sourcePoints = [...city];
      setMapPoints(true);
    } else {
      sourcePoints = [...points];
      setMapPoints(false);
    }
    
    // Se TODOS está marcado, não filtra por tipo de equipamento
    if (filters[0]) { // TODOS
      let result = [...sourcePoints];
      // Aplica filtros adicionais (online, serial, empresa)
      result = applyAdditionalFilters(result, filters[9], serial, company);
      setAllPoints(result);
      return;
    }
    
    // Definir mapeamento dos filtros para funções de filtro
    const typeFilters = [
      null, // TODOS (índice 0)
      item => item.located, // LOCAÇÃO (índice 1)
      item => item.properties.data.serial.includes('SIMT'), // SIMT (índice 2)
      item => item.properties.data.serial.includes('BIMT'), // BIMT (índice 3)
      item => item.properties.data.serial.includes('CPMT'), // CPMT (índice 4)
      item => item.properties.data.serial.includes('EEMT'), // EEMT (índice 5)
      item => item.properties.data.serial.includes('ESMR'), // ESMR (índice 6)
      null, // ESTADOS (índice 7) - já aplicado na seleção de sourcePoints
      null, // CIDADES (índice 8) - já aplicado na seleção de sourcePoints
      null, // ONLINE (índice 9) - aplicado separadamente
      item => item.properties.data.serial.includes('SEMT') // 999DRY (índice 10)
    ];
    
    let combinedResults = [];
    
    // Verifica se locação está ativo para aplicar combinações específicas
    if (filters[1]) { // LOCAÇÃO
      // Base de locação
      const locacaoBase = sourcePoints.filter(item => item.located);
      
      // Verifica se apenas locação está ativa
      const onlyLocacao = !filters.slice(2, 7).some(Boolean) && !filters[10];
      
      if (onlyLocacao) {
        combinedResults = [...locacaoBase];
      } else {
        // Combina locação com outros filtros
        for (let i = 2; i < typeFilters.length; i++) {
          if (i !== 7 && i !== 8 && i !== 9 && filters[i] && typeFilters[i]) {
            combinedResults.push(...locacaoBase.filter(typeFilters[i]));
          }
        }
      }
    } else {
      // Aplica cada tipo de filtro individualmente
      for (let i = 2; i < typeFilters.length; i++) {
        if (i !== 7 && i !== 8 && i !== 9 && filters[i] && typeFilters[i]) {
          combinedResults.push(...sourcePoints.filter(typeFilters[i]));
        }
      }
    }
    
    // Remove duplicatas usando ID
    let uniqueResults;
    if (combinedResults.length > 0) {
      const seenIds = new Set();
      uniqueResults = combinedResults.filter(item => {
        const id = item.properties.id;
        if (seenIds.has(id)) return false;
        seenIds.add(id);
        return true;
      });
    } else {
      // Se nenhum filtro específico estiver ativo, usa todos os pontos da fonte
      uniqueResults = [...sourcePoints];
    }
    
    // Aplica filtros adicionais (online, serial, empresa)
    const finalResults = applyAdditionalFilters(uniqueResults, filters[9], serial, company);
    
    setAllPoints(finalResults);
  };
  
  // Função auxiliar para aplicar filtros adicionais
  const applyAdditionalFilters = (points, isOnline, serial, company) => {
    let result = [...points];
    
    // Filtro de equipamentos online
    if (isOnline) {
      result = result.filter(item => item?.properties?.data?.isConnected);
    }
    
    // Filtro por número de série
    if (serial) {
      result = result.filter(item => 
        item?.properties?.data?.serial?.toUpperCase().includes(serial)
      );
    }
    
    // Filtro por empresa
    if (company && company !== '') {
      const companyEquipments = companies
        .find(i => i.name === company)?.equipments || [];
      result = result.filter(item => 
        companyEquipments.includes(item.properties.data.serial)
      );
    }
    
    return result;
  };

  return (
    <div style={{ width: '100%', height: '100vh', backgroundColor: '#292E29' }}>
      {loading && <Reload />}

      <Filter filter={filter} companyNames={companyNames} count={allPoints.length} />

      {points.length > 0 && (
        <Map
          center={center}
          containerElement={<div style={{ height: '100%' }} />}
          googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${GOOGLE_API_KEY}&language=pt-br`}
          loadingElement={<div style={{ height: '100%' }} />}
          mapElement={<div style={{ height: '100%' }} />}
          mapPoints={mapPoints}
          zoom={zoom}
          points={allPoints}
        />
      )}
    </div>
  );
};
export default Maps;
