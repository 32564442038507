import React, { useEffect, useState } from 'react';
import axios from 'utils/axios';
import { getToken } from 'actions/sessionActions';
import { Page, NotifyModal, SearchBar, BootstrapMultiSelect } from 'components';
import FileUpload from '../../components/FileUpload/FileUpload'
import { Forms, Equipments, Cooperatives } from './components';
import { useParams, useHistory } from 'react-router-dom';

import Header from '../../components/Header/Header';
import Reload from 'components/Reload/Reload';

import {
  Tab,
  Col,
  Row,
  Tabs,
  Card,
  Form,
  Modal,
  Button,
  FloatingLabel,
} from 'react-bootstrap';

import ApiMServer from 'services/ApiMServer';
import ApiGServer from 'services/ApiGServer';

const CooperativesDetail = (props) => {
  const _id = useParams().id;

  const history = useHistory();

  const data = JSON.parse(localStorage.getItem('userData'));
  if (data?.permission?.cooperatives?.read !== true) {
    window.location.replace('/home');
  }
  const idUser = data?._id;
  const email = data?.email;

  const [openDialogActivation, setOpenDialogActivation] = useState(false);
  const [openDialogPlanRental, setOpenDialogPlanRental] = useState(false);
  const [openDialogPlanOwner, setOpenDialogPlanOwner] = useState(false);

  const [daystoactivate, setDaystoactivate] = useState('');
  const [equipments, setEquipments] = useState([]);
  const [equipmentsAll, setEquipmentsAll] = useState([]);
  const [serialsnumbers, setSerialsNumbers] = useState([]);
  const [id, setId] = useState('');
  const [permission, setPermission] = useState(false);
  const [alertMessage, setAlertMessage] = useState({ "message": '', "type": '' });
  const [selectedEquipments, setSelectedEquipments] = useState([]);
  const [selectedUnits, setSelectedUnits] = useState([]);
  const [chave, setChave] = useState('equipamentos');
  const [unit, setUnit] = useState([]);
  const [unidades, setUnidades] = useState([]);
  const [rentalEquipments, setRentalEquipments] = useState([])
  const [ownerEquipments, setOwnerEquipments] = useState([])

  const [allEmails, setAllEmails] = useState([]);

  const [unitsDetail, setUnitsDetail] = useState([]);
  const [unitsDetailAll, setUnitsDetailAll] = useState([]);

  const [searchData, setSearchData] = useState('');

  const handleSearchData = (e) => {
    setSearchData(e.target.value)
  };

  const [loading, setLoading] = useState(true);

  const [type, setType] = useState({ "color": "", "status": "" });
  const [modalShow, setModalShow] = useState(false);

  const handleAlertModalSuccess = (msg) => {
    setType({ "color": "#198754", "status": msg })
    setModalShow(true)
  }

  const handleAlertModalError = (msg) => {
    setType({ "color": "red", "status": msg })
    setModalShow(true)
  }

  const [units, setUnits] = useState({
    typeCompany: false,
    crossCheck: false,
    equipments: [],
    localEquipament: [],
    units: [],
    id: '',
    name: '',
    cnpj: '',
    ncontract: '',
    fone: '',
    email: '',
    password: '',
    secretOnlyKey: '',
    accessSecretOnlyKey: '',
    dominioEmail: '',
    createdAt: '',
    updatedAt: '',
    restrictAcess: false
  });

  const [emails, setEmails] = useState([])

  useEffect(() => {
    (async () => {
      const { data } = await ApiMServer.get('/users/manager', { headers: { id: _id } });
      setEmails(data);
    })();
  }, [_id]);

  const [checkbox, setCheckbox] = useState({
    simt: true,
    semt: true,
    bitm: true,
    cpmt: true,
    eemt: true,
    esmr: true
  });

  const handleChangeModeAll = (mode) => {
    let validate = window.confirm('Confirma essa ação para todos os equipamentos desta empresa?')
    if (validate) {
      let list = equipments.map(item => item.serialnumber);
      (async () => {
        await axios(process.env.REACT_APP_API_GLOBAL_SERVER, getToken()).post(
          `/equipment/updatemodegroup`, { "list": list, "mode": mode }
        )
        getEquipments();
      })()
    }
    return;
  }

  const handleOpenUpdateActivation = () => {
    setOpenDialogActivation(true);
  };

  const handleCloseUpdateActivation = () => {
    setOpenDialogActivation(false);
  };

  const handleAddEquipments = async () => {
    if (!selectedEquipments.length) return;
    const data = await ApiMServer.post('/company/addequipment', { "_id": id, "serialnumber": selectedEquipments })

    if (data.status === 200) {
      setKey(!key);

      for (var i in selectedEquipments) {
        units.equipments.push(selectedEquipments[i]);
      }
      setSelectedEquipments([]);
      getEquipments();
      handleAlertModalSuccess("Adicionado com sucesso!");
    } else if (data.status === 202) {
      let confirm = window.confirm(`Cadastrado em ${data.data.name}, ir para a empresa?`);
      if (confirm) {
        if (data.data.typeCompany === true) {
          window.location.href = `/cooperatives/${data.data._id}`;
        } else {
          window.location.href = `/units/${data.data._id}`;
        }
      }
      return;
    } else {
      return
    }
  };

  const handleRemoveEquipments = async () => {
    if (!selectedEquipments.length) return;
    if (!window.confirm("Confirma remover?")) {
      return;
    } else {
      try {
        await ApiMServer.post('/company/rmequipment', {
          "_id": id,
          "serialnumber": selectedEquipments
        });
        setKey(!key);
        selectedEquipments.forEach((i) => {
          const index = units.equipments.indexOf(i);
          if (index > -1) {
            units.equipments.splice(index, 1);
          }
        })
        setSelectedEquipments([]);
        getEquipments();
        handleAlertModalSuccess("Removido com sucesso!");
      } catch (error) {
        console.log(error.response);

        handleAlertModalError('Falha ao atualizar!');
      }
    };
  };

  const handleAddUnits = async () => {
    if (!selectedUnits.length) return;
    var idUnits = [];
    for (let i = 0; i < selectedUnits.length; i++) {
      var value = selectedUnits[i];
      var j = unidades.filter((item) => item.name === value).map((i) => {
        return i._id;
      })
      idUnits.push(j[0]);
    }
    try {
      const { data } = await ApiMServer.post('/company/addunit', {
        "id": id,
        "units": idUnits
      });
      console.log(data);

      setKeyTwo(!keyTwo);
      for (var i in selectedUnits) {
        units.units.push(selectedUnits[i]);
      }
      setSelectedUnits([]);
      handleCompanyDetail();
      handleAlertModalSuccess("Adicionado com sucesso!");
    } catch (error) {
      console.log(error.response);

      handleAlertModalError('Falha ao atualizar!')
    }
  };

  const handleRemoveUnits = async () => {
    if (!selectedUnits.length) return;
    if (window.confirm("Confirma remover?")) {
      var idUnits = [];
      for (let i = 0; i < selectedUnits.length; i++) {
        var value = selectedUnits[i];
        var j = unidades.filter((item) => item.name === value).map((i) => {
          return i._id;
        })
        idUnits.push(j[0]);
      }
      try {
        await ApiMServer.post('/company/rmunit', {
          "id": id,
          "units": idUnits
        })
        setKeyTwo(!keyTwo);
        selectedUnits.forEach((i) => {
          const index = units.units.indexOf(i);
          if (index > -1) {
            units.units.splice(index, 1);
          }
        })

        handleGetCompany();
        handleCompanyDetail();
        handleAlertModalSuccess("Removido com sucesso!");

      } catch (error) {
        handleAlertModalError('Falha ao atualizar!')
      }
    };
  };

  const handleRemoveAllUnits = async () => {
    if (window.confirm("Confirma remover tudo?")) {
      try {
        await axios(process.env.REACT_APP_API_MOTOMCO, getToken())
          .post('/company/rmallunit', {
            "id": id,
          }).then((response) => {
            if (response.status === 200) {
              handleGetCompany();
              handleCompanyDetail();
              handleAlertModalSuccess("Removido com sucesso!");
            }
          });
      } catch (error) {
        handleAlertModalError('Falha ao atualizar!')
      }
    };
  };

  const handleDaysChange = (event, field, value) => {
    event.persist && event.persist();
    setDaystoactivate(value);
  };

  useEffect(() => {
    const { id } = props.match.params;
    setId(id);
  }, [props]);

  const handleGetCompany = async () => {
    let nameUnits = [];
    try {
      const { data } = await ApiMServer.get(`/company/get`);
      setUnidades(data);
      nameUnits = data
        .filter(unit => unit.name && !unit.typeCompany)
        .map(item => item.name);

      setUnit(nameUnits);
    } catch (error) {
      handleAlertModalError('Falha ao atualizar!');
    }
  }

  const handleCompanyDetail = async () => {
    try {
      const { data: unit } = await ApiMServer.post(`/company/v1/getcooperativedetail`, { id: _id });
      unit.password = "";
      setUnits(unit);

      // Preparar as promessas para serem executadas em paralelo
      const equipmentPromise = unit.equipments.length > 0
        ? ApiGServer.post(`/equipment/filter`, { filter: unit.equipments })
        : Promise.resolve(null);

      const unitsPromise = unit.units.length > 0
        ? ApiMServer.post('/company/get/units', { arrayIds: unit.units })
        : Promise.resolve(null);

      const [equipmentResponse, unitsResponse] = await Promise.all([equipmentPromise, unitsPromise]);

      if (equipmentResponse) {
        setEquipments(equipmentResponse.data);
        setEquipmentsAll(equipmentResponse.data);
        handlefilterEquipments(equipmentResponse.data);
        localStorage.setItem("tempEquipments", JSON.stringify(equipmentResponse.data));
      }

      if (unitsResponse) {
        setUnitsDetail(unitsResponse.data);
        setUnitsDetailAll(unitsResponse.data);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);

      handleAlertModalError('Falha ao atualizar!');
      setLoading(false);
    }
  };

  const handlefilterEquipments = (equipamentos) => {
    const rentalEquipments = equipamentos
      .filter(equip => equip.mode === "RENTAL")
      .map(equip => equip.serialnumber)

    setRentalEquipments(rentalEquipments)

    const ownerEquipments = equipamentos
      .filter(equip => equip.mode === "OWNER")
      .map(equip => equip.serialnumber)
    setOwnerEquipments(ownerEquipments)
  }


  const [planRental, setPlanRental] = useState("");
  const handlePlanChangeRental = (plan) => {
    setOpenDialogPlanRental(true);
    setPlanRental(plan);
  };

  const handleUpdatePlanChangeRental = () => {
    handlePlan(planRental, rentalEquipments);
  };

  const [planOwner, setPlanOwner] = useState("");
  const handlePlanChangeOwner = (plan) => {
    setOpenDialogPlanOwner(true);
    setPlanOwner(plan);
  };

  const handleUpdatePlanChangeOwner = () => {
    handlePlan(planOwner, ownerEquipments);
  };

  const handleCloseDialogPlan = () => {
    setOpenDialogPlanRental(false);
    setOpenDialogPlanOwner(false);
  };

  const handlePlan = async (newPlan, serials) => {
    if (planRental || planOwner) {
      setEquipments((prevEquipments) => {
        const updatedEquipments = prevEquipments.map((equipment) => {
          if (serials.includes(equipment.serialnumber)) {
            return { ...equipment, plan: newPlan };
          }
          return equipment;
        });
        return updatedEquipments;
      });

      try {
        await ApiGServer.post(`/equipment/updateplan`, { filter: serials, plan: newPlan });
        handleAlertModalSuccess("Atualizado com sucesso!");
        handleCloseDialogPlan();
      } catch (error) {
        handleAlertModalError('Falha ao atualizar!');
        handleCloseDialogPlan();
      }
    } else {
      return
    }
  };

  useEffect(() => {
    const { permission } = JSON.parse(localStorage.getItem('userData'));
    setPermission(permission?.cooperatives?.write);
    handleGetCompany();
    handleCompanyDetail();
  }, []);

  const getEquipments = () => {
    (async () => {
      await ApiGServer.post(`/equipment/filter`, { "filter": units.equipments })
        .then((response) => {
          setEquipments(response.data);
          setEquipmentsAll(response.data);
          handlefilterEquipments(response.data)
        });
    })();
  };

  const removeEquipment = (serialnumber) => {
    let confirm = window.confirm(`Confirma remover o equipamento: ${serialnumber}`);
    if (confirm) {
      (async () => {
        await ApiMServer.post(`/company/rmequipment`, { "_id": id, "serialnumber": serialnumber });
        const index = units.equipments.indexOf(serialnumber);
        if (index > -1) {
          units.equipments.splice(index, 1);
        }
        getEquipments();
      })();
    }
  };

  const removeUnit = async (_id) => {
    let confirm = window.confirm(`Confirma remover a unidade?`);
    if (confirm) {
      try {
        await ApiMServer.post(`/company/rmunit`, { "id": id, "units": _id })
        handleCompanyDetail();
        handleAlertModalSuccess("Removido com sucesso!");
      } catch (error) {
        handleAlertModalError('Falha ao atualizar!')
      }
    }
  };

  const updateDaysActivation = async () => {
    handleCloseUpdateActivation();

    if (!daystoactivate) {
      alert('O campo dias para Ativação deve ser preenchido!');
      return;
    }

    let value = parseInt(daystoactivate);
    if (isNaN(value)) {
      alert('O campo dias para Ativação deve ser preenchido com um número inteiro!');
      return;
    }

    if (value < 0 || value > 4095) {
      alert('O número de dias para Ativação deve estar entre 0 e 4095!');
      return;
    }

    try {
      await ApiGServer.post('/equipment/updatedaysactivationCompany', {
        list: units.equipments,
        daystoactivate: Number(daystoactivate).toString(16).toUpperCase()
      });
      getEquipments();
      handleAlertModalSuccess('Atualizado com sucesso!');
    } catch (error) {
      console.log(error.response);

      handleAlertModalError('Falha ao atualizar!')
    }
  };

  const getAllEmail = async () => {
    try {
      const { data } = await ApiMServer.get('/user/all-email');
      setAllEmails(data);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    (async () => {
      await ApiGServer.get('/equipment/serialsnumber')
        .then((response) => {
          var data = response.data;
          data.sort(function (a, b) {
            return a.split('-')[1] - b.split('-')[1];
          }).sort();
          setSerialsNumbers(data);
        });
    })();

    getAllEmail();

  }, []);

  const handleFieldChange = (event, field, value) => {
    setUnits((values) => ({
      ...values,
      [field]: value
    }));
  };

  const handleCheckBoxdChange = (field, value) => {
    setCheckbox((values) => ({
      ...values,
      [field]: value
    }));
  };

  const updateAddressAndLocation = (newAddress, newLat, newLng) => {
    setUnits(prevUnits => ({
      ...prevUnits,
      address: newAddress,
      location: {
        lat: String(newLat),
        lng: String(newLng)
      }
    }));
  };

  const filterData = () => {
    var fequips = [];

    if (checkbox.simt) {
      Array.prototype.push.apply(fequips, equipments.filter(e => e.serialnumber.split("-")[0] === "SIMT"));
    }
    if (checkbox.semt) {
      Array.prototype.push.apply(fequips, equipments.filter(e => e.serialnumber.split("-")[0] === "SEMT"));
    }
    if (checkbox.bitm) {
      Array.prototype.push.apply(fequips, equipments.filter(e => e.serialnumber.split("-")[0] === "BIMT"));
    }
    if (checkbox.cpmt) {
      Array.prototype.push.apply(fequips, equipments.filter(e => e.serialnumber.split("-")[0] === "CPMT"));
    }
    if (checkbox.eemt) {
      Array.prototype.push.apply(fequips, equipments.filter(e => e.serialnumber.split("-")[0] === "EEMT"));
    }
    if (checkbox.esmr) {
      Array.prototype.push.apply(fequips, equipments.filter(e => e.serialnumber.split("-")[0] === "ESMR"));
    }

    return fequips;
  }

  const handleSubmit = async () => {
    let data = units;

    delete data.equipments;
    delete data.createdAt;
    delete data.updatedAt;
    delete data.__v;

    data.idUser = idUser;

    try {
      await Promise.all([
        ApiMServer.post('/company/update', data),
        ApiMServer.post('/company/creategestor', { emails, id: units._id })
      ]);

      handleAlertModalSuccess('Atualizado com sucesso!');
    } catch (error) {
      console.error('Erro ao atualizar:', error);
      handleAlertModalError('Falha ao atualizar os dados. Tente novamente.');
    }
  };

  const handleGetRepor = async () => {
    setLoading(true);
    try {
      window.open(`${process.env.REACT_APP_API_MOTOMCO}/managementReport/${_id}`)
      setLoading(false);
    } catch (error) {
      handleAlertModalError('Falha ao atualizar!');
      setLoading(false);
    }
  }

  const handleOpenCompany = async () => {
    try {
      await ApiMServer.post(`/company/addgestor`, { id: _id, emails: email });
      window.open(`${process.env.REACT_APP_SGU}`);
    } catch (error) {
      console.log(error);
    }
  }

  const [charts, setCharts] = useState("");

  const handleDeleteCharts = async () => {
    var confirm = window.confirm("Confirma apagar curva?")
    if (!confirm || !charts) {
      return
    }
    try {
      await ApiMServer.delete(`/api/v1/deletecharts?_id=${charts._id}`);
      setCharts("")
      handleAlertModalSuccess("Apagado com sucesso!")
    } catch (error) {
      handleAlertModalError("Erro ao apagar!")
    }
  }

  const handleGetChart = async () => {
    if (!_id) {
      return
    }
    try {
      let { data } = await ApiMServer.get(`/api/v1/getcharts?company_id=${_id}`);
      if (data) {
        setCharts(data)
      }
    } catch (error) {
      console.log(error.message);
    }
  }

  const handleReport = () => {
    localStorage.setItem("EQUIP_FILTER", JSON.stringify(units.equipments))
    localStorage.setItem("EQUIPMENTS", JSON.stringify(equipments))
    history.push('/relatorio');
  }

  const handleSearchEquipments = () => {
    if (!searchData.trim()) {
      setEquipments(equipmentsAll);
      return;
    }

    const filteredEquipments = equipmentsAll.filter((item) =>
      item?.nameEquipament?.toLowerCase().includes(searchData.toLowerCase()) ||
      item?.serialnumber?.toLowerCase().includes(searchData.toLowerCase())
    );

    setEquipments(filteredEquipments);
  };

  const handleSearchUnits = () => {
    if (!searchData.trim()) {
      setUnitsDetail(unitsDetailAll);
      return;
    }

    const filteredUnits = unitsDetailAll.filter((unit) =>
      unit?.name?.toLowerCase().includes(searchData.toLowerCase()) ||
      unit?.cnpj?.toLowerCase().includes(searchData.toLowerCase())
    );

    setUnitsDetail(filteredUnits);
  };

  useEffect(() => {
    handleGetChart()
  }, [])

  const [key, setKey] = useState(false);
  const [keyTwo, setKeyTwo] = useState(false);
  const [openUnits, setOpenUnits] = useState(false);

  useEffect(() => {
    if (selectedUnits.length) {
      setOpenUnits(true)
    } else {
      setOpenUnits(false)
    }
  }, [selectedUnits])

  return (
    <Page className="p-3" title="Cooperativas">

      <Header page={"Cooperativas"} />

      {loading && <Reload />}

      <NotifyModal
        type={type}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />

      <Card className='mb-2 mt-2 p-2'>
        <Row>
          <Col>

            <Button
              className='ms-2 mt-2 mb-2'
              disabled={!permission}
              variant="success"
              onClick={() => { handleOpenCompany() }}
            >
              Visualização do Cliente
            </Button>

            <Button
              className='ms-2 mt-2 mb-2'
              disabled={!permission}
              variant="success"
              onClick={() => { handleGetRepor() }}
            >
              {'Relatório Gerencial'}
            </Button>

            <Button
              className='ms-2 mt-2 mb-2'
              disabled={!permission}
              variant="success"
              onClick={() => { handleReport() }}
            >
              Relatório de Ativação
            </Button>

          </Col>
        </Row>
      </Card>

      <Forms
        alertMessage={alertMessage}
        fieldChange={handleFieldChange}
        onSubmit={handleSubmit}
        handleAlertModalError={handleAlertModalError}
        units={units}
        permission={permission}
        setEmails={setEmails}
        emails={emails}
        setUnits={setUnits}
        setLocation={updateAddressAndLocation}
        allEmails={allEmails}
      />

      <Card className='mb-3 mt-3'>
        <Card.Header>
          <h5 className='mt-2 mb-2'>Equipamentos</h5>
        </Card.Header>
        <Card.Body>

          <Row className='g-2'>
            <Col md>
              <BootstrapMultiSelect
                label={"Equipamentos"}
                options={serialsnumbers}
                selectedEquipments={selectedEquipments}
                setSelectedEquipments={setSelectedEquipments}
              />

              <Button
                className='mt-2'
                disabled={!permission || !selectedEquipments.length}
                variant="success"
                onClick={handleAddEquipments}
              >
                Adicionar Equipamentos
              </Button>

              <Button
                className='mt-2 ms-2'
                disabled={!permission || !selectedEquipments.length}
                variant="outline-danger"
                onClick={handleRemoveEquipments}
              >
                Remover Equipamentos
              </Button>
            </Col>

            <Col md>
              {units && units.typeCompany ?
                <div>
                  <BootstrapMultiSelect
                    label={"Unidades"}
                    options={unit}
                    selectedEquipments={selectedUnits}
                    setSelectedEquipments={setSelectedUnits}
                  />

                  <Button
                    className='mt-2'
                    disabled={!permission || !selectedUnits.length}
                    variant="success"
                    onClick={handleAddUnits}
                  >
                    Adicionar Unidades
                  </Button>
                  <Button
                    disabled={!permission || !selectedUnits.length}
                    className='mt-2 ms-2'
                    variant="outline-danger"
                    onClick={handleRemoveUnits}
                  >
                    Remover Unidades
                  </Button>

                  {/* <Button
                    disabled={!permission || !selectedUnits.length}
                    className='mt-2 ms-2'
                    variant="outline-danger"
                    onClick={handleRemoveAllUnits}
                  >
                    Remover Todas
                  </Button> */}

                </div>
                : null}
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card>
        <Card.Header>
          <h5 className='mt-2 mb-2'>Curva Personalizada</h5>
        </Card.Header>
        <Card.Body>

          {/* Type = CUSTOM, INMETRO, COMPLEMENT */}
          <FileUpload
            type={"CUSTOM"}
            company={_id}
            permission={permission}
            handleGetChart={handleGetChart}
            setAlertMessage={setAlertMessage}
            handleAlertModalError={handleAlertModalError}
            handleAlertModalSuccess={handleAlertModalSuccess}
          />

          {charts._id ?
            <>
              <h6 className='mt-3 mb-2'>
                {`Curva existente: ${charts?.chartCustom[0]?.hash}`}
              </h6>
              <Button
                disabled={!permission}
                variant="outline-danger"
                onClick={() => {
                  handleDeleteCharts()
                }}
              >
                Apagar curva personalizada existente
              </Button>
            </>
            :
            <h6 className='mt-3 mb-2'>{`Nenhuma curva personalizada`}</h6>
          }
        </Card.Body>
      </Card>


      <Card className='mt-3 mb-3'>
        <Card.Header>
          <h5 className='mt-2 mb-2'>Planos</h5>
        </Card.Header>
        <Card.Body>
          <Row className='g-2'>
            <Col md>
              {rentalEquipments.length > 0 &&
                <div>
                  <h6 className='mt-1 mb-2'>Locação</h6>
                  <div className="mt-2">
                    <Button
                      disabled={!permission}
                      onClick={() => handlePlanChangeRental('NORMAL')}
                      variant="outline-success"
                    >
                      NORMAL
                    </Button>
                    <Button
                      disabled={!permission}
                      onClick={() => handlePlanChangeRental('FOB')}
                      className="ms-2"
                      variant="outline-success"
                    >
                      FOB
                    </Button>
                  </div>
                </div>
              }

              {rentalEquipments.length > 0 && ownerEquipments.length > 0 ? <hr /> : null}

              {ownerEquipments.length > 0 &&
                <div>
                  <h6 className='mt-1 mb-2'>Proprietário</h6>
                  <div className="mt-2">
                    <Button
                      disabled={!permission}
                      onClick={() => handlePlanChangeOwner('NORMAL')}
                      variant="outline-success"
                    >
                      NORMAL
                    </Button>
                    <Button
                      disabled={!permission}
                      onClick={() => handlePlanChangeOwner('PLUS')}
                      className="ms-2"
                      variant="outline-success"
                    >
                      PLUS
                    </Button>
                    <Button
                      disabled={!permission}
                      onClick={() => handlePlanChangeOwner('FLEX')}
                      className="ms-2"
                      variant="outline-success"
                    >
                      FLEX
                    </Button>
                    <Button
                      disabled={permission === true ? false : true}
                      onClick={() => handlePlanChangeOwner('PREMIUM')}
                      className="ms-2"
                      variant="outline-success"
                    >
                      PREMIUM
                    </Button>
                  </div>
                </div>
              }
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card className='mb-3 mt-3'>
        <Card.Header>
          <h5 className='mt-2 mb-2'>Alterar dias de Ativação</h5>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col md>
              <FloatingLabel style={{ width: 250 }} controlId="floatingInput" label="Dias" className="mb-3">
                <Form.Control
                  type="Number"
                  name="Dias"
                  placeholder="Dias"
                  onChange={(event) =>
                    handleDaysChange(event, 'daystoactivate', event.target.value)
                  }
                  disabled={!permission}
                />
              </FloatingLabel>

              <Button
                disabled={!permission}
                onClick={handleOpenUpdateActivation}
                variant="success"
              >
                Alterar
              </Button>
            </Col>
          </Row>

          <hr />

          <Row>
            <Col md>
              <h6 className='mt-1 mb-2'>Alterar modo dos equipamentos</h6>

              <Button
                disabled={!permission}
                onClick={() => handleChangeModeAll('RENTAL')}
                variant="success"
              >
                Todos para locação
              </Button>
              <Button
                disabled={!permission}
                onClick={() => handleChangeModeAll('OWNER')}
                className="ms-2"
                variant="success"
              >
                Todos para proprietário
              </Button>
              <Button
                disabled={!permission}
                onClick={() => handleChangeModeAll('ISENTKEY')}
                className="ms-2"
                variant="success"
              >
                Isenção de taxas
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Tabs
        className="mb-3 mt-3"
        activeKey={chave}
        onSelect={(key) => setChave(key)}
      >
        <Tab eventKey="equipamentos" title="EQUIPAMENTOS">
          <>
            <SearchBar
              onSearch={handleSearchEquipments}
              onSearchData={handleSearchData}
              searchData={searchData}
            />

            <div
              style={{ width: '100%', display: 'flex', flexWrap: 'wrap', gap: '1rem' }}
              className='mt-3 mb-2'>
              {[
                { key: 'simt', label: 'SIMT' },
                { key: 'semt', label: 'SEMT' },
                { key: 'bitm', label: 'BIMT' },
                { key: 'eemt', label: 'EEMT' },
                { key: 'cpmt', label: 'CPMT' },
                { key: 'esmr', label: 'ESMR' }
              ].map((item) => (
                <div key={item.key}>
                  <Form.Check
                    isValid
                    type="checkbox"
                    label={item.label}
                    checked={checkbox[item.key]}
                    onChange={(event) => handleCheckBoxdChange(item.key, event.target.checked)}
                  />
                </div>
              ))}
            </div>

            {equipments && equipments.length ? (
              <Equipments
                data={filterData()}
                onRemoveButton={removeEquipment}
                permission={permission}
              />
            ) : (
              <h5>Nenhum equipamento encontrado.</h5>
            )}
          </>
        </Tab>

        <Tab eventKey="unidades" title="UNIDADES">
          <>
            <SearchBar
              onSearch={handleSearchUnits}
              onSearchData={handleSearchData}
              searchData={searchData}
            />
            {unitsDetail && unitsDetail.length > 0 ? (
              <Cooperatives
                data={unitsDetail}
                onRemoveButton={removeUnit}
                permission={permission}
              />
            ) :
              <h5>Nenhuma unidade encontrada.</h5>
            }
          </>
        </Tab>
      </Tabs>

      <Modal show={openDialogActivation} onHide={handleCloseUpdateActivation} centered>
        <Modal.Header closeButton>
          <Modal.Title>Alterar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Deseja alterar os dias de ativação de todos os equipamentos da{' '}
            <strong>{units.name}</strong>?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseUpdateActivation}>
            Cancelar
          </Button>
          <Button variant="success" onClick={updateDaysActivation}>
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={openDialogPlanRental} onHide={handleCloseDialogPlan} centered>
        <Modal.Header closeButton>
          <Modal.Title>Alterar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Confirma alterar para o plano: <strong>{planRental}</strong> todos os equipamentos desta Cooperativa?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDialogPlan}>
            Cancelar
          </Button>
          <Button variant="success" onClick={handleUpdatePlanChangeRental}>
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={openDialogPlanOwner} onHide={handleCloseDialogPlan} centered>
        <Modal.Header closeButton>
          <Modal.Title>Alterar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Confirma alterar para o plano: <strong>{planRental}</strong> todos os equipamentos desta Cooperativa?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDialogPlan}>
            Cancelar
          </Button>
          <Button variant="success" onClick={handleUpdatePlanChangeOwner}>
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>

    </Page>
  );
};

export default CooperativesDetail;
