import React, { useState, useCallback } from 'react';
import { Table, Pagination, Button, Card } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { FaSortUp, FaSortDown, FaSort } from 'react-icons/fa';

const Results = ({ data, onRemoveButton, permission }) => {
  const history = useHistory();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [orderBy, setOrderBy] = useState(''); // Nova state para coluna de ordenação
  const [orderDirection, setOrderDirection] = useState('asc'); // Nova state para direção da ordenação

  const handleChangePage = useCallback((newPage) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = useCallback((event) => {
    setRowsPerPage(Number(event.target.value));
    setPage(0); // Reset to first page ao mudar linhas por página
  }, []);

  // Função para manipular o clique no cabeçalho e ordenar
  const handleSort = (column) => {
    if (orderBy === column) {
      // Inverte a direção se a coluna já está selecionada
      setOrderDirection(orderDirection === 'asc' ? 'desc' : 'asc');
    } else {
      // Define uma nova coluna e inicia com ascendente
      setOrderBy(column);
      setOrderDirection('asc');
    }
    // Resetar para primeira página quando mudar ordenação
    setPage(0);
  };

  // Função para ordenar os dados com base na coluna e direção
  const sortData = (dataToSort) => {
    if (!orderBy) return dataToSort;

    return [...dataToSort].sort((a, b) => {
      let valueA, valueB;

      // Definir valores para comparação com base na coluna
      switch (orderBy) {
        case 'name':
          valueA = a?.name || '';
          valueB = b?.name || '';
          break;
        case 'cnpj':
          valueA = a?.cnpj || '';
          valueB = b?.cnpj || '';
          break;
        case 'address':
          valueA = a?.address || '';
          valueB = b?.address || '';
          break;
        case 'units':
          valueA = a?.units?.length || 0;
          valueB = b?.units?.length || 0;
          break;
        case 'equipments':
          valueA = a?.equipments?.length || 0;
          valueB = b?.equipments?.length || 0;
          break;
        default:
          valueA = '';
          valueB = '';
      }

      // Comparação dos valores considerando a direção
      if (typeof valueA === 'number' && typeof valueB === 'number') {
        return orderDirection === 'asc' ? valueA - valueB : valueB - valueA;
      } else {
        const comparison = String(valueA).localeCompare(String(valueB));
        return orderDirection === 'asc' ? comparison : -comparison;
      }
    });
  };

  // Renderizar ícone de ordenação usando react-icons
  const renderSortIcon = (column) => {
    if (orderBy !== column) {
      return <FaSort style={{ marginLeft: 5, opacity: 0.5 }} />;
    }
    return orderDirection === 'asc' 
      ? <FaSortUp style={{ marginLeft: 5, opacity: 1 }} /> 
      : <FaSortDown style={{ marginLeft: 5, opacity: 1 }} />;
  };

  // Aplicar ordenação antes da paginação
  const sortedData = sortData(data);
  const totalPages = Math.ceil(sortedData.length / rowsPerPage);
  const paginatedData = sortedData.slice(page * rowsPerPage, (page + 1) * rowsPerPage);

  const getPaginationRange = () => {
    const start = Math.max(page - 2, 0);
    const end = Math.min(page + 2, totalPages - 1);
    let range = [];

    for (let i = start; i <= end; i++) {
      range.push(i);
    }
    return range;
  };

  const handleRowClick = (id) => {
    history.push(`/cooperatives/${id}`);
  };

  return (
    <div>
      <p className="text-muted">
        {data.length} registros encontrados. Página {page + 1} de {totalPages}
      </p>
      <Card>
        <Card.Header>Cooperativas</Card.Header>
        <Card.Body>
          <Table style={{ fontSize: "0.9rem" }} striped bordered hover="true" responsive size='sm'>
            <thead>
              <tr className="table-secondary">
                <th 
                  className="text-center col-sm-3" 
                  onClick={() => handleSort('name')}
                  style={{ cursor: 'pointer' }}
                >
                  NOME {renderSortIcon('name')}
                </th>
                <th 
                  className="text-center"
                  onClick={() => handleSort('cnpj')}
                  style={{ cursor: 'pointer' }}
                >
                  CNPJ {renderSortIcon('cnpj')}
                </th>
                <th 
                  className="text-center"
                  onClick={() => handleSort('address')}
                  style={{ cursor: 'pointer' }}
                >
                  ENDEREÇO {renderSortIcon('address')}
                </th>
                <th 
                  className="text-center"
                  onClick={() => handleSort('units')}
                  style={{ cursor: 'pointer' }}
                >
                  UNIDADES {renderSortIcon('units')}
                </th>
                <th 
                  className="text-center"
                  onClick={() => handleSort('equipments')}
                  style={{ cursor: 'pointer' }}
                >
                  EQUIPAMENTOS {renderSortIcon('equipments')}
                </th>
                <th className="text-center">AÇÃO</th>
              </tr>
            </thead>
            <tbody>
              {paginatedData.map((item) => (
                <tr
                  key={item._id}
                  className="clickable-row"
                  onClick={() => handleRowClick(item._id)}
                  style={{ cursor: 'pointer' }}
                  hover="true"
                  disabled={!permission?.read}
                >
                  <td className="pt-2">{item.name || '--'}</td>
                  <td className="text-center pt-2">{item.cnpj || '--'}</td>
                  <td className="text-center pt-2">
                    {item.address
                      ? item.address.length < 40
                        ? item.address
                        : `${item.address.slice(0, 40)}...`
                      : '--'}
                  </td>
                  <td className="text-center pt-2">{item.units?.length || '--'}</td>
                  <td className="text-center pt-2">{item.equipments?.length || '--'}</td>
                  <td className="text-center">
                    <Button
                      size="sm"
                      className='ms-2'
                      variant="outline-danger"
                      onClick={(e) => {
                        e.stopPropagation();
                        onRemoveButton(item._id);
                      }}
                      disabled={!permission?.write}
                    >
                      Remover
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
        <Card.Footer className="d-flex justify-content-between align-items-center">
          <div>
            <label>
              Linhas por página:{' '}
              <select
                value={rowsPerPage}
                onChange={handleChangeRowsPerPage}>
                {[10, 20, 40].map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </label>
          </div>
          <Pagination className="custom-pagination" variant="dark">
            <Pagination.Prev
              onClick={() => handleChangePage(page - 1)}
              disabled={page === 0}
              aria-label="Página anterior"
            />
            {getPaginationRange().map((index) => (
              <Pagination.Item
                key={index}
                active={index === page}
                onClick={() => handleChangePage(index)}
              >
                {index + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next
              onClick={() => handleChangePage(page + 1)}
              disabled={page >= totalPages - 1}
              aria-label="Próxima página"
            />
          </Pagination>
        </Card.Footer>
      </Card>
    </div>
  );
};

export default Results;
