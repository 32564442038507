import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, Table, Pagination, Button } from 'react-bootstrap';
import { FaSortUp, FaSortDown, FaSort } from 'react-icons/fa';

const Results = ({ data, permission }) => {
  const history = useHistory();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [orderBy, setOrderBy] = useState(''); // Nova state para coluna de ordenação
  const [orderDirection, setOrderDirection] = useState('asc'); // Nova state para direção da ordenação

  // Função para manipular o clique no cabeçalho e ordenar
  const handleSort = (column) => {
    if (orderBy === column) {
      // Inverte a direção se a coluna já está selecionada
      setOrderDirection(orderDirection === 'asc' ? 'desc' : 'asc');
    } else {
      // Define uma nova coluna e inicia com ascendente
      setOrderBy(column);
      setOrderDirection('asc');
    }
    // Resetar para primeira página quando mudar ordenação
    setPage(0);
  };

  // Função para ordenar os dados com base na coluna e direção
  const sortData = (dataToSort) => {
    if (!orderBy) return dataToSort;

    return [...dataToSort].sort((a, b) => {
      let valueA, valueB;

      // Definir valores para comparação com base na coluna
      switch (orderBy) {
        case 'serialnumber':
          valueA = a?.serialnumber || '';
          valueB = b?.serialnumber || '';
          break;
        case 'company':
          valueA = a?.company?.name || '';
          valueB = b?.company?.name || '';
          break;
        case 'address':
          valueA = a?.address?.formatedAddr || '';
          valueB = b?.address?.formatedAddr || '';
          break;
        case 'mode':
          valueA = a?.mode || '';
          valueB = b?.mode || '';
          break;
        case 'plan':
          valueA = a?.plan || 'NORMAL';
          valueB = b?.plan || 'NORMAL';
          break;
        case 'activation':
          valueA = a?.daystoactivate ? parseInt(a.daystoactivate, 16) : 0;
          valueB = b?.daystoactivate ? parseInt(b.daystoactivate, 16) : 0;
          break;
        case 'software':
          valueA = a?.versaosw || '';
          valueB = b?.versaosw || '';
          break;
        default:
          valueA = '';
          valueB = '';
      }

      // Comparação dos valores considerando a direção
      if (typeof valueA === 'number' && typeof valueB === 'number') {
        return orderDirection === 'asc' ? valueA - valueB : valueB - valueA;
      } else {
        const comparison = String(valueA).localeCompare(String(valueB));
        return orderDirection === 'asc' ? comparison : -comparison;
      }
    });
  };

  // Aplicar ordenação antes da paginação
  const sortedData = sortData(data);
  const totalPages = Math.ceil(sortedData.length / rowsPerPage);
  const paginatedData = sortedData.slice(page * rowsPerPage, (page + 1) * rowsPerPage);

  // Renderizar ícone de ordenação usando react-icons
  const renderSortIcon = (column) => {
    if (orderBy !== column) {
      return <FaSort style={{ marginLeft: 5, opacity: 0.5 }} />;
    }
    return orderDirection === 'asc' 
      ? <FaSortUp style={{ marginLeft: 5, opacity: 1 }} /> 
      : <FaSortDown style={{ marginLeft: 5, opacity: 1 }} />;
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(Number(event.target.value));
    setPage(0); // Reset to first page
  };

  const handleClick = (serial) => {
    history.push(`/support/${serial}`);
  };

  const getPaginationRange = () => {
    const start = Math.max(page - 2, 0);
    const end = Math.min(page + 2, totalPages - 1);
    let range = [];

    for (let i = start; i <= end; i++) {
      range.push(i);
    }
    return range;
  };

  return (
    <div>
      <p>
        {data.length} registros encontrados. Página {page + 1} de {totalPages}
      </p>
      <Card>
        <Card.Header>Equipamentos</Card.Header>
        <Card.Body>
          <Table style={{ fontSize: "0.9rem" }} striped bordered hover="true" responsive size='sm'>
            <thead>
              <tr className="table-secondary">
                <th 
                  className="text-center" 
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleSort('serialnumber')}
                >
                  SERIAL {renderSortIcon('serialnumber')}
                </th>
                <th 
                  className="text-center" 
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleSort('company')}
                >
                  EMPRESA {renderSortIcon('company')}
                </th>
                <th 
                  className="text-center" 
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleSort('address')}
                >
                  ENDEREÇO {renderSortIcon('address')}
                </th>
                <th 
                  className="text-center" 
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleSort('mode')}
                >
                  MODO {renderSortIcon('mode')}
                </th>
                <th 
                  className="text-center" 
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleSort('plan')}
                >
                  PLANO {renderSortIcon('plan')}
                </th>
                <th 
                  className="text-center" 
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleSort('activation')}
                >
                  ATIVAÇÃO {renderSortIcon('activation')}
                </th>
                <th 
                  className="text-center" 
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleSort('software')}
                >
                  SOFTWARE {renderSortIcon('software')}
                </th>
                <th className="text-center">AÇÃO</th>
              </tr>
            </thead>
            <tbody>
              {paginatedData.map((item) => (
                <tr key={item._id}>
                  <td className="text-center pt-2 pb-2">{item?.serialnumber || '--'}</td>
                  <td className="text-center pt-2 pb-2">
                    {item?.company?.name
                      ? item.company?.name.length < 40
                        ? item.company?.name
                        : `${item.company?.name.slice(0, 40)}...`
                      : '--'}
                  </td>
                  <td className="text-center pt-2 pb-2">
                    {item?.address?.formatedAddr
                      ? item?.address?.formatedAddr?.length < 40
                        ? item?.address?.formatedAddr
                        : `${item?.address?.formatedAddr?.slice(0, 40)}...`
                      : '--'}
                  </td>
                  <td className="text-center pt-2 pb-2">
                    {item?.mode === 'RENTAL' ? 'Locação' : 'Proprietário'}
                  </td>
                  <td className="text-center pt-2 pb-2">{item?.plan || 'NORMAL'}</td>
                  <td className="text-center pt-2 pb-2">
                    {item?.daystoactivate ? parseInt(item.daystoactivate, 16) : '--'}
                  </td>
                  <td className="text-center pt-2 pb-2">{item?.versaosw || '--'}</td>
                  <td className="text-center">
                    <Button
                      variant="success"
                      size="sm"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleClick(item.serialnumber)
                      }}
                      disabled={!permission?.write}
                    >
                      Conectar
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
        <Card.Footer className="d-flex justify-content-between align-items-center">
          <div>
            <label>
              Linhas por página:{' '}
              <select
                value={rowsPerPage}
                onChange={handleRowsPerPageChange}
                className="form-select form-select-sm"
              >
                {[10, 20, 40].map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </label>
          </div>
          <Pagination className="custom-pagination" variant="dark">
            <Pagination.Prev
              onClick={() => handlePageChange(page - 1)}
              disabled={page === 0}
              aria-label="Página anterior"
            />
            {getPaginationRange().map((index) => (
              <Pagination.Item
                key={index}
                active={index === page}
                onClick={() => handlePageChange(index)}
              >
                {index + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next
              onClick={() => handlePageChange(page + 1)}
              disabled={page >= totalPages - 1}
              aria-label="Próxima página"

            />
          </Pagination>
        </Card.Footer>
      </Card>
    </div>
  );
};

export default Results;
