import React, { useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, Table, Badge, Pagination, Button } from 'react-bootstrap';

const Logs = ({ serialNumber, data }) => {
  const history = useHistory();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(40);

  const totalPages = Math.ceil(data.length / rowsPerPage);
  const paginatedData = data.slice(page * rowsPerPage, (page + 1) * rowsPerPage);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(Number(event.target.value));
    setPage(0); // Reset to first page
  };

  const getPaginationRange = () => {
    const start = Math.max(page - 2, 0);
    const end = Math.min(page + 2, totalPages - 1);
    let range = [];

    for (let i = start; i <= end; i++) {
      range.push(i);
    }
    return range;
  };

  const getVariant = (erro) => {
    switch (erro) {
      case "Crítico":
        return "danger";
      case "Moderado":
        return "warning";
      case "Baixo":
        return "info";
      case "Sucesso":
        return "success";
      case "Secondary":
        return "secondary";
      default:
        return "primary";
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();

    // Diferença em milissegundos e conversão para horas
    const diffInMs = now.getTime() - date.getTime();
    const diffInHours = diffInMs / (1000 * 60 * 60);
    const isOld = diffInHours > 12;

    // Formatando a data corretamente sem converter fuso horário
    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const year = String(date.getUTCFullYear()).slice(-2);
    const hours = String(date.getUTCHours()).padStart(2, "0");
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");
    const seconds = String(date.getUTCSeconds()).padStart(2, "0");

    return {
      formattedDate: `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`,
      isOld,
    };
  };

  const groupedData = useMemo(() => {
    const grouped = [];
    let currentGroup = null;

    paginatedData.forEach((item) => {
      if (item.tipo === "Sucesso") {
        if (currentGroup &&
          currentGroup.error === item.error &&
          Math.abs(new Date(currentGroup.lastDate) - new Date(item.date)) < 1000 * 60 * 5) { // 5 minutos
          currentGroup.count++;
          currentGroup.lastDate = item.date;
        } else {
          if (currentGroup) {
            grouped.push(currentGroup);
          }
          currentGroup = {
            ...item,
            count: 1,
            lastDate: item.date,
            isGroup: true
          };
        }
      } else {
        if (currentGroup) {
          grouped.push(currentGroup);
          currentGroup = null;
        }
        grouped.push(item);
      }
    });

    if (currentGroup) {
      grouped.push(currentGroup);
    }

    return grouped;
  }, [paginatedData]);

  return (
    <div>
      {data.length > 0 ? (
        <>
          <p>
            {data.length} registros encontrados. Página {page + 1} de {totalPages}
          </p>
          <Card>
            <Card.Body>
              <Table style={{ fontSize: "0.9rem" }} striped bordered hover="true" responsive size='sm'>
                <thead>
                  <tr className="table-secondary">
                    <th className="text-center">TIPO</th>
                    <th className="text-center">STATUS</th>
                    <th className="text-center">HORA</th>
                  </tr>
                </thead>
                <tbody>
                  {groupedData.map((item, index) => (
                    <tr key={`${item.date}-${index}`}>
                      <td className="text-center pt-2 pb-2">
                        <Badge className='p-1' bg={item?.tipo ? getVariant(item?.tipo) : "secondary"}>
                          {item?.tipo}
                          {item.isGroup && item.count > 1 && (
                            <Badge bg="light" text="dark" pill className="ms-1">
                              {item.count}x
                            </Badge>
                          )}
                        </Badge>
                      </td>

                      <td className="text-center pt-2 pb-2" title={item?.error || "--"}>
                        {item?.error
                          ? item?.error?.length < 50
                            ? item?.error
                            : `${item?.error?.slice(0, 50)}...`
                          : 'Sem status'}
                      </td>

                      {(() => {
                        if (item?.date) {
                          const { formattedDate, isOld } = formatDate(item.date);
                          return (
                            <td className={`text-center pt-2 pb-2 ${isOld ? 'text-danger' : ''}`}>
                              {formattedDate}
                              {item.isGroup && item.count > 1 && (
                                <small className="d-block text-muted">
                                  até {formatDate(item.lastDate).formattedDate}
                                </small>
                              )}
                            </td>
                          );
                        }
                        return <td className="text-center pt-2 pb-2">--</td>;
                      })()}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
            <Card.Footer className="d-flex justify-content-between align-items-center">
              <div>
                <label>
                  Linhas por página:{' '}
                  <select
                    value={rowsPerPage}
                    onChange={handleRowsPerPageChange}
                    className="form-select form-select-sm"
                  >
                    {[10, 20, 40].map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </label>
              </div>
              <Pagination className="custom-pagination" variant="dark">
                <Pagination.Prev
                  onClick={() => handlePageChange(page - 1)}
                  disabled={page === 0}
                  aria-label="Página anterior"
                />
                {getPaginationRange().map((index) => (
                  <Pagination.Item
                    key={index}
                    active={index === page}
                    onClick={() => handlePageChange(index)}
                  >
                    {index + 1}
                  </Pagination.Item>
                ))}
                <Pagination.Next
                  onClick={() => handlePageChange(page + 1)}
                  disabled={page >= totalPages - 1}
                  aria-label="Próxima página"

                />
              </Pagination>
            </Card.Footer>
          </Card>
        </>
      ) : (
        <Card>
          <Card.Body className="text-center">
            <h5>Nenhum registro encontrado</h5>
          </Card.Body>
        </Card>
      )}
    </div>
  );
};

export default Logs;
