import React, { useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, Table, Badge, Pagination, Button } from 'react-bootstrap';
import { Bar } from 'react-chartjs-2';

const Graphic = ({ serialNumber, data }) => {
 
  const errorCounts = useMemo(() => {
    const counts = {
      Crítico: 0,
      Moderado: 0,
      Baixo: 0,
      Sucesso: 0,
      Secondary: 0,
      Outros: 0
    };

    data.forEach((item) => {
      if (counts[item.tipo] !== undefined) {
        counts[item.tipo]++;
      } else {
        counts.Outros++;
      }
    });

    return counts;
  }, [data]);

  const chartData = {
    labels: Object.keys(errorCounts),
    datasets: [
      {
        label: 'Quantidade',
        data: Object.values(errorCounts),
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(255, 159, 64, 0.2)',
          'rgba(255, 205, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(201, 203, 207, 0.2)'
        ],
        borderColor: [
          'rgb(255, 99, 132)',
          'rgb(255, 159, 64)',
          'rgb(255, 205, 86)',
          'rgb(75, 192, 192)',
          'rgb(153, 102, 255)',
          'rgb(201, 203, 207)'
        ],
        borderWidth: 1
      }
    ]
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Gráfico de Erros do Equipamento'
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Quantidade'
        }
      },
      x: {
        title: {
          display: true,
          text: 'Tipo de Erro'
        }
      }
    }
  };

  return (
    <div>
      {data.length > 0 ? (
        <>
          <Card>
            <Card.Body>
              <Bar data={chartData} options={chartOptions} height={150} />
            </Card.Body>
          </Card>
        </>
      ) : (
        <Card>
          <Card.Body className="text-center">
            <h5>Nenhum registro encontrado</h5>
          </Card.Body>
        </Card>
      )}
    </div>
  );
};

export default Graphic;
