import React, { useState, useEffect } from 'react';
import { getToken } from 'actions/sessionActions';
import { Page, SearchBar } from 'components';
import { Results } from './components';
import Header from '../../components/Header/Header';
import Reload from '../../components/Reload/Reload';

import { Button, Modal } from 'react-bootstrap';

import axios from 'utils/axios';
import ApiMServer from 'services/ApiMServer';

const UserPassword = () => {
  const user = JSON.parse(localStorage.getItem('userData'));
  if (user?.permission?.userspassword?.read !== true) {
    window.location.replace('/home');
  }

  const [users, setUsers] = useState([]);
  const [tempUsers, setTempUsers] = useState([]);
  const [open, setOpen] = useState(false);
  const [searchData, setSearchData] = useState('');
  const [userId, setUserId] = useState('');
  const [permission, setPermission] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleClickOpen = (id) => {
    setUserId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setUserId('');
    setOpen(false);
  };

  const getUsers = async () => {
    try {
      const { data } = await ApiMServer.get('/userspassword');
      let usersSort = data.sort(function (a, b) {
        if (a.name > b.name) {
          return 1;
        }
        if (a.name < b.name) {
          return -1;
        }
        return 0;
      });
      setUsers(usersSort);
      setTempUsers(usersSort);
    } catch (error) {
      alert("Erro ao carregar usuários!");
    }
    setLoading(false);
  };

  const removeUserPermission = () => {
    (async () => {
      setOpen(false);
      await axios(
        process.env.REACT_APP_API_MOTOMCO,
        getToken()
      ).post(`/removepasswordacess`, { id: userId, token: user.token });
      getUsers();
    })();
  };

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    setPermission(user?.permission?.userspassword?.write);
  }, []);

  const handleSearch = () => {
    if (!searchData.trim()) {
      setTempUsers(users);
      return;
    }

    const usersSearch = users.filter((user) =>
      ['name', 'email', 'cpfcnpj'].some(
        (key) =>
          user?.[key] &&
          user[key].toUpperCase().includes(searchData.toUpperCase())
      )
    );
    setTempUsers(usersSearch);
  };

  const handleSearchData = (e) => {
    setSearchData(e.target.value);
  };

  return (
    <Page className="p-3" title="Usuários">
      <Header page={"Usuários Gera Senha"} />

      {loading && <Reload />}

      <SearchBar
        onSearch={handleSearch}
        onSearchData={handleSearchData}
        searchData={searchData}
      />

      {tempUsers && (
        <Results
          permission={permission}
          data={tempUsers}
          onRemoveButton={handleClickOpen}
        />
      )}

      <Modal
        show={open}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Remover
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Confirma Remover o acesso ao Gera Senha deste usuário?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Não
          </Button>
          <Button variant="danger" onClick={removeUserPermission}>
            Sim
          </Button>
        </Modal.Footer>
      </Modal>

    </Page>
  );
};

export default UserPassword;
