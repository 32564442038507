import React, { useState, useCallback } from 'react';
import { Table, Button, Card, Pagination } from 'react-bootstrap';
import dateFormat from 'utils/dateformat';

const Results = ({ data, onRemoveButton, permission }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const handleChangePage = useCallback((newPage) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = useCallback((event) => {
    setRowsPerPage(Number(event.target.value));
  }, []);

  const totalPages = Math.ceil(data.length / rowsPerPage);
  const paginatedData = data.slice(page * rowsPerPage, (page + 1) * rowsPerPage);

  const getPaginationRange = () => {
    const start = Math.max(page - 2, 0);
    const end = Math.min(page + 2, totalPages - 1);
    let range = [];

    for (let i = start; i <= end; i++) {
      range.push(i);
    }
    return range;
  };

  const formatCpfCnpj = (value) => {
    if (!value) return '--';

    const cleaned = value.replace(/\D/g, ''); // Remove caracteres não numéricos

    if (cleaned.length === 11) {
      // CPF: 123.456.789-00 → 123.***.***-00
      return `${cleaned.slice(0, 3)}.***.***-${cleaned.slice(-2)}`;
    } else if (cleaned.length === 14) {
      // CNPJ: 12.345.678/0001-00 → 12.***.***/0001-00
      return `${cleaned.slice(0, 2)}.***.***/${cleaned.slice(-6)}`;
    }

    return value; // Retorna o valor original caso não seja um CPF/CNPJ válido
  };

  const handleRowClick = (id) => {
    window.location.href = `/users/${id}`;
  };

  return (
    <div>
      <p>
        {data.length} registros encontrados. Página {page + 1} de {totalPages}
      </p>
      <Card>
        <Card.Header>Usuários</Card.Header>
        <Card.Body>
          <Table style={{ fontSize: "0.9rem" }} striped bordered hover="true" responsive size='sm'>
            <thead>
              <tr className="table-secondary">
                <th className="text-center">NOME</th>
                <th className="text-center">E-MAIL</th>
                <th className="text-center">CPF/CNPJ</th>
                <th className="text-center">PERMISSÃO</th>
                <th className="text-center">FONE</th>
                <th className="text-center">EMPRESA</th>
                <th className="text-center">ÚLTIMO LOGIN</th>
                <th className="text-center">AÇÃO</th>
              </tr>
            </thead>
            <tbody>
              {paginatedData.map((item) => (
                <tr
                  key={item._id}
                  className="clickable-row"
                  onClick={() => handleRowClick(item._id)}
                  style={{ cursor: 'pointer' }}
                  hover="true"
                >
                  <td className="pt-2">{item.name || '--'}</td>
                  <td className="text-center pt-2 pb-2">{item.email || '--'}</td>
                  <td className="text-center pt-2 pb-2">{formatCpfCnpj(item.cpfcnpj) || '--'}</td>
                  <td className="text-center pt-2 pb-2">{item.generatePasswordPermission || '--'}</td>
                  <td className="text-center pt-2 pb-2">{item.fone || '--'}</td>
                  <td className="text-center pt-2 pb-2">{item.company || '--'}</td>
                  <td className="text-center pt-2 pb-2">{item.updatedAt ? dateFormat(item.updatedAt, "UTC:dd/mm/yyyy' 'HH:MM") : "--"}</td>
                  <td className="text-center" style={{ width: 140 }}>
                    <Button
                      variant="outline-danger"
                      size="sm"
                      disabled={!permission}
                      onClick={(e) => {
                        e.stopPropagation();
                        onRemoveButton(item._id);
                      }}
                    >
                      Remover Acesso
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
        <Card.Footer className="d-flex justify-content-between align-items-center">
          <div>
            <label>
              Linhas por página:{' '}
              <select
                value={rowsPerPage}
                onChange={handleChangeRowsPerPage}>
                {[10, 20, 40].map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </label>
          </div>
          <Pagination className="custom-pagination" variant="dark">
            <Pagination.Prev
              onClick={() => handleChangePage(page - 1)}
              disabled={page === 0}
              aria-label="Página anterior"

            />
            {getPaginationRange().map((index) => (
              <Pagination.Item
                key={index}
                active={index === page}
                onClick={() => handleChangePage(index)}
              >
                {index + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next
              onClick={() => handleChangePage(page + 1)}
              disabled={page >= totalPages - 1}
              aria-label="Próxima página"

            />
          </Pagination>
        </Card.Footer>
      </Card>
    </div>
  );
};

export default Results;
