// src/MeuComponente.js
import React, { useEffect, useState } from 'react';
import { SearchBar, Page } from 'components';
import Results from './components/Results';

import Api from 'services/Api';
import ApiGServer from 'services/ApiGServer';
import Reload from '../../components/Reload/Reload';
import Header from '../../components/Header/Header';

const SuporteDry = () => {
  const user = JSON.parse(localStorage.getItem('userData'))
  if (user?.permission?.supportdry?.read !== true) {
    window.location.replace('/home');
  }

  const [filterEquipaments, setFilterEquipaments] = useState([]);
  const [allEquipaments, setAllEquipaments] = useState([]);
  const [searchData, setSearchData] = useState('');
  const [permission, setPermission] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let { data } = await Api.get('/connection/connected');
        const filteredData = data.filter(item => item.includes("SEMT"));
        if (filteredData.length === 0) {
          setLoading(false);
          return;
        }
        handleDetailEquipaments(filteredData.sort((a, b) => a.localeCompare(b)));
      } catch (error) {
        console.error('Erro ao buscar os dados:', error);
        setLoading(false)
      }
    };
    fetchData();

    setPermission(user?.permission?.supportdry);

    const interval1 = setInterval(() => {
      fetchData();
    }, 30000);
    return () => { clearInterval(interval1) };
  }, []);

  const handleDetailEquipaments = async (equipments) => {
    try {
      const [equipmentResponse, connectionResponse] = await Promise.all([
        ApiGServer.post(`/equipment/filter`, { filter: equipments }),
        Api.get('/troubles/filter-list', { params: { filter: equipments } }) // Alterado para passar params corretamente
      ]);

      const equipmentData = equipmentResponse.data;
      const connectionData = connectionResponse.data;

      const connectionMap = new Map(connectionData.map(item => [item.serial, item]));

      const mergedData = equipmentData.map(equip => ({
        ...equip,
        status: connectionMap.get(equip.serialnumber) || null
      }));

      setFilterEquipaments(mergedData);
      setAllEquipaments(mergedData);
    } catch (error) {
      console.log(error?.message);
    } finally {
      setLoading(false);
    }
  };


  const handleSearch = () => {
    if (searchData === '') {
      setAllEquipaments(filterEquipaments);
    } else {
      const equipamentsSearch = [];
      filterEquipaments.forEach((company) => {
        if (
          (company?.serialnumber?.toLowerCase().includes(searchData.toLowerCase())) ||
          (company?.nameEquipament?.toLowerCase().includes(searchData.toLowerCase())) ||
          (company?.company?.name?.toLowerCase().includes(searchData.toLowerCase())) ||
          (company?.versaosw?.toLowerCase().includes(searchData.toLowerCase()))
        ) {
          equipamentsSearch.push(company)
        }
      });
      setAllEquipaments(equipamentsSearch);
    }
  };

  const handleSearchData = (e) => {
    setSearchData(e.target.value)
  };

  return (
    <Page className="p-3" title="Suporte Dry">
      <Header page={"Suporte Dry"} />

      {loading && <Reload />}

      <SearchBar
        onSearch={handleSearch}
        onSearchData={handleSearchData}
        searchData={searchData}
      />

      {allEquipaments && (
        <Results
          data={allEquipaments}
          permission={permission}
        />
      )}
    </Page >
  );
}

export default SuporteDry;
