import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Modal, Button } from "react-bootstrap"
import axios from 'utils/axios';
import { getToken } from 'actions/sessionActions';
import { Page, SearchBar } from 'components';
import { Results } from './components';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

import Header from '../../components/Header/Header';
import Reload from '../../components/Reload/Reload';
import ApiGServer from 'services/ApiGServer';


const Equipments = (props) => {
  const user = JSON.parse(localStorage.getItem('userData'));
  if (user?.permission?.equipments?.read !== true) {
    window.location.replace('/home');
  }

  const [equipments, setEquipments] = useState([]);
  const [equipmentsToShow, setEquipmentsToshow] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [searchData, setSearchData] = useState('');
  const [equipmentId, setEquipmentId] = useState('');
  const [permission, setPermission] = useState(false);
  const [loading, setLoading] = useState(true);
  const [checkOwner, setCheckOwner] = useState(false);
  const [checkLocation, setCheckLocation] = useState(false);

  const [stateWifi, setStateWifi] = useState(0);
  const [statePh, setStatePh] = useState(0);

  const handleStatateWifi = () => {
    setStateWifi(stateWifi === 0 ? 1 : stateWifi === 1 ? 2 : 0)
    handleSearch(checkOwner, checkLocation, stateWifi === 0 ? 1 : stateWifi === 1 ? 2 : 0, statePh);
  };

  const handleStatatePh = () => {
    setStatePh(statePh === 0 ? 1 : statePh === 1 ? 2 : 0)
    handleSearch(checkOwner, checkLocation, stateWifi, statePh === 0 ? 1 : statePh === 1 ? 2 : 0);
  };

  const handleClickOpen = (id) => {
    setEquipmentId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeProprietari = () => {
    if (checkOwner === false) {
      setCheckLocation(false);
    }
    setCheckOwner(!checkOwner);
    handleSearch(!checkOwner, false, stateWifi, statePh);
  }

  const handleChangeLocate = () => {
    if (checkLocation === false) {
      setCheckOwner(false);
    }
    setCheckLocation(!checkLocation);
    handleSearch(false, !checkLocation, stateWifi, statePh);
  }

  const getEquipments = async () => {
    setLoading(true);
    try {
      const { data } = await ApiGServer.get(`/equipment/all`);
      const sortedData = data.sort((a, b) => a.serialnumber.localeCompare(b.serialnumber));
      setEquipments(sortedData);
      setEquipmentsToshow(sortedData);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const removeEquipment = async () => {
    try {
      setOpen(false);
      await axios(process.env.REACT_APP_API_MOTOMCO, getToken()).post('/equipment/delete', { "_id": equipmentId });
      getEquipments();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const { permission } = JSON.parse(localStorage.getItem('userData'));
    setPermission(permission?.equipments)
    getEquipments();
  }, [])

  const handleSearch = (f0, f1, f2, f3) => {
    if (f0 == null) {
      f0 = checkOwner;
    }
    if (f1 == null) {
      f1 = checkLocation;
    }
    if (f2 == null) {
      f2 = stateWifi;
    }
    if (f3 == null) {
      f3 = statePh;
    }

    if (searchData === '' && f0 === false && f1 === false && f2 === 0 && f3 === 0) {
      setEquipmentsToshow(equipments);
    } else {

      var equipmentsSearch = [...equipments];

      if (searchData !== '') {
        equipmentsSearch = equipmentsSearch.filter(item =>
          item?.serialnumber?.toLowerCase().includes(searchData.toLowerCase()) ||
          item?.versaosw?.toLowerCase().includes(searchData.toLowerCase())
        );
      }

      if (f0) {
        equipmentsSearch = equipmentsSearch.filter(item =>
          item.mode === "OWNER"
        );
      }

      if (f1) {
        equipmentsSearch = equipmentsSearch.filter(item =>
          item.mode === 'RENTAL'
        );
      }

      if (f2 === 1) {
        equipmentsSearch = equipmentsSearch.filter(v => v.wf === "OK")
      } else if (f2 === 2) {
        equipmentsSearch = equipmentsSearch.filter(v => v.wf !== "OK")
      }

      if (f3 === 1) {
        equipmentsSearch = equipmentsSearch.filter(v => v.calph === "OK")
      } else if (f3 === 2) {
        equipmentsSearch = equipmentsSearch.filter(v => v.calph !== "OK")
      }

      setEquipmentsToshow(equipmentsSearch);
    }
  };

  const handleSearchData = (e) => {
    setSearchData(e.target.value);
  };

  const handleGetReport = async () => {
    try {
      setLoading(true);
      const { data } = await ApiGServer.get('/equipment/report');
      if (data) {
        generatePDF(data);
      } else {
        alert('Não foi possível obter os dados do relatório.');
      }
    } catch (error) {
      console.error('Erro ao obter relatório:', error);
      alert('Erro ao gerar relatório. Por favor, tente novamente.');
    } finally {
      setLoading(false);
    }
  }

  const generatePDF = (reportData) => {
    try {
      // Corrigir a atribuição do vfs
      pdfMake.vfs = pdfFonts.pdfMake ? pdfFonts.pdfMake.vfs : pdfFonts;
      
      const { total, generatedAt, equipments } = reportData;
      
      // Verificações de segurança
      if (!pdfMake.vfs) {
        console.error('Erro: pdfMake.vfs não está definido');
        alert('Erro ao gerar o PDF: Fontes não disponíveis');
        return;
      }
      
      if (!equipments || !Array.isArray(equipments)) {
        console.error('Dados de equipamento inválidos para o relatório PDF');
        alert('Erro ao gerar relatório. Dados inválidos.');
        return;
      }
    
      // Formatar a data corretamente
      const formattedDate = new Date(generatedAt).toLocaleString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      });

      // Valor unitário para cada equipamento
      const valorUnitario = 10.00;
      const valorTotal = valorUnitario * equipments.length;
      
      // Dados para a tabela com cálculos financeiros
      const tableData = equipments.map((eq, index) => [
        index + 1, // Numeração sequencial
        eq.nameEquipament || "Sem nome",
        eq.serialnumber || "N/A",
        eq.mode || "N/A",
        eq.versaosw || "N/A",
        eq.versaofwpri || "N/A", // Versão Firmware Principal
        eq.versaofwosc || "N/A", // Versão Firmware Oscilador
        eq.wf === "OK" ? "OK" : "NOK", // Status WiFi
        eq.calph === "OK" ? "OK" : "NOK", // Status calibração PH
        `R$ ${valorUnitario.toFixed(2).replace('.', ',')}`, // Valor unitário formatado
        `R$ ${valorUnitario.toFixed(2).replace('.', ',')}` // Valor total (mesmo que unitário neste caso)
      ]);
    
      // Definição do documento com informações técnicas e financeiras
      const docDefinition = {
        pageSize: 'A4',
        pageOrientation: 'landscape',
        footer: function(currentPage, pageCount) {
          return {
            text: `Página ${currentPage} de ${pageCount}`,
            alignment: 'center',
            fontSize: 8,
            margin: [0, 10, 0, 0]
          };
        },
        header: function() {
          return {
            text: 'RELATÓRIO TÉCNICO DE EQUIPAMENTOS REV 4',
            alignment: 'center',
            fontSize: 10,
            bold: true,
            margin: [0, 10, 0, 0]
          };
        },
        content: [
          { text: "Relatório Técnico de Equipamentos Rev 4", style: "header" },
          { text: `Data de Geração: ${formattedDate}`, style: "subheader" },
          { text: `Total de Equipamentos: ${equipments.length}`, style: "subheader" },
          
          // Informações financeiras
          { 
            columns: [
              { 
                width: '50%',
                text: [
                  { text: 'Valor Unitário: ', bold: true },
                  `R$ ${valorUnitario.toFixed(2).replace('.', ',')}`
                ] 
              },
              { 
                width: '50%',
                text: [
                  { text: 'Valor Total: ', bold: true },
                  `R$ ${valorTotal.toFixed(2).replace('.', ',')}` 
                ] 
              }
            ],
            style: "financialInfo"
          },
          
          { text: "Listagem Técnica de Equipamentos:", style: "subheader", margin: [0, 10, 0, 5] },
          {
            table: {
              headerRows: 1,
              widths: [20, '*', '*', 50, 50, 50, 50, 40, 40, 60, 60],
              body: [
                [
                  { text: 'Nº', style: 'tableHeader' },
                  { text: 'Nome', style: 'tableHeader' },
                  { text: 'Serial', style: 'tableHeader' },
                  { text: 'Modo', style: 'tableHeader' },
                  { text: 'Versão SW', style: 'tableHeader' },
                  { text: 'FW Principal', style: 'tableHeader' },
                  { text: 'FW Oscilador', style: 'tableHeader' },
                  { text: 'WiFi', style: 'tableHeader' },
                  { text: 'PH', style: 'tableHeader' },
                  { text: 'Val. Unit.', style: 'tableHeader' },
                  { text: 'Val. Total', style: 'tableHeader' }
                ],
                ...tableData
              ]
            }
          },
          
          // Resumo financeiro
          { 
            text: `Resumo Financeiro`, 
            style: "subheader",
            margin: [0, 20, 0, 5]
          },
          {
            table: {
              widths: ['*', 100],
              body: [
                [
                  { text: 'Total de Equipamentos:', bold: true },
                  { text: equipments.length, alignment: 'right' }
                ],
                [
                  { text: 'Valor Unitário:', bold: true },
                  { text: `R$ ${valorUnitario.toFixed(2).replace('.', ',')}`, alignment: 'right' }
                ],
                [
                  { text: 'Valor Total:', bold: true },
                  { text: `R$ ${valorTotal.toFixed(2).replace('.', ',')}`, alignment: 'right' }
                ]
              ]
            },
            layout: 'noBorders',
            margin: [0, 0, 0, 20]
          }
        ],
        styles: {
          header: { 
            fontSize: 18, 
            bold: true, 
            marginBottom: 10, 
            alignment: "center" 
          },
          subheader: { 
            fontSize: 12, 
            bold: true, 
            marginBottom: 5,
            color: '#333333'
          },
          tableHeader: {
            bold: true,
            fontSize: 8,
            fillColor: '#eeeeee',
            alignment: 'center'
          },
          financialInfo: {
            fontSize: 10,
            margin: [0, 5, 0, 10],
            color: '#444444'
          }
        },
        defaultStyle: {
          fontSize: 8
        }
      };
    
      // Criar e baixar o PDF
      pdfMake.createPdf(docDefinition).download("relatorio_tecnico_equipamentos.pdf");
    } catch (error) {
      console.error('Erro ao gerar PDF:', error);
      alert('Ocorreu um erro ao gerar o relatório PDF: ' + error.message);
    }
  };

  return (
    <Page className="p-3" title="Equipamentos">
      <Header page={'Equipamentos'} />

      {loading && <Reload />}

      <SearchBar
        onSearch={() => handleSearch(checkOwner, checkLocation, stateWifi, statePh)}
        onSearchData={handleSearchData}
        searchData={searchData}
      />

      {user?.email === "engenharia10@locsolution.com" || user?.email === "engenharia6@locsolution.com" ? (
        <Row className="align-items-center mt-3 mb-2">
          <Col xs="auto">
            <Button
              variant="outline-success"
              onClick={() => {
                handleGetReport('orange');
              }}
            >
              Relatório de Equipamentos Rev 4
            </Button>
          </Col>
        </Row>
      ) : null}

      <Row className="align-items-center mt-3 mb-2">
        <Col xs="auto">
          <Form.Check type="checkbox" inline>
            <Form.Check.Input
              type="checkbox"
              onChange={handleChangeProprietari}
              isValid
              checked={checkOwner}
            />
            <Form.Check.Label>Proprietário</Form.Check.Label>
          </Form.Check>
        </Col>

        <Col xs="auto">
          <Form.Check type="checkbox" inline>
            <Form.Check.Input
              type="checkbox"
              onChange={handleChangeLocate}
              isValid
              checked={checkLocation}
            />
            <Form.Check.Label>Locação</Form.Check.Label>
          </Form.Check>
        </Col>

        <Col xs="auto">
          <Form.Check type="checkbox" inline>
            <Form.Check.Input
              type="checkbox"
              onChange={handleStatateWifi}
              isValid
              checked={stateWifi === 1 ? true : stateWifi === 2 ? false : undefined}
            />
            <Form.Check.Label>Wi-Fi</Form.Check.Label>
          </Form.Check>
        </Col>

        <Col xs="auto">
          <Form.Check type="checkbox" inline>
            <Form.Check.Input
              type="checkbox"
              onChange={handleStatatePh}
              isValid
              checked={statePh === 1 ? true : statePh === 2 ? false : undefined}
            />
            <Form.Check.Label>PH Calibrado</Form.Check.Label>
          </Form.Check>
        </Col>
      </Row>

      {equipments && (
        <Results
          data={equipmentsToShow}
          permission={permission}
          onRemoveButton={handleClickOpen}
        />
      )}

      <Modal
        show={open}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title id="alert-dialog-title">Remover</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Confirma Remover este Equipamento?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Não
          </Button>
          <Button variant="danger" onClick={removeEquipment}>
            Sim
          </Button>
        </Modal.Footer>
      </Modal>

    </Page >
  );
};

export default Equipments;
