import React, { useState, useEffect, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { getToken } from 'actions/sessionActions';
import { Page } from 'components';
import { Form, Form2, Form3 } from './components';
import Results from '../Relatorio/components/Results/Results';
import { Card, Row, Col, Button } from 'react-bootstrap';
import Header from '../../components/Header/Header';
import dateformat from 'utils/dateformat';
import copy from 'clipboard-copy';
import axios from 'utils/axios';

const EquipmentsDetail = (props) => {

  const { serial } = props.match.params;
  const [serials,] = useState(serial);

  const user = JSON.parse(localStorage.getItem('userData'));
  if (user?.permission?.equipments?.read !== true) {
    window.location.replace('/home');
  }

  const [equipments, setEquipments] = useState({
    charts: {
      principal: '',
      complementar: ''
    },
    daystoactivate: '',
    _id: '',
    equipid: '',
    serialnumber: '',
    serialnumbersequence: '',
    cupid: '',
    lastlat: '',
    lastlng: '',
    versaosw: '',
    versaofwpri: '',
    versaofwosc: '',
    sha1curva: '',
    createdAt: '',
    updatedAt: '',
    mode: '',
    dcal: '',
    isentkey: false,
    plan: '',
    observation: ''
  });

  const [unlock, setUnlock] = useState({
    email: '',
    name: '',
    lat: '',
    lng: '',
    sn: '',
    password: '',
    createdAt: '',
    updatedAt: '',
    address: ''
  });

  const [passwordGenerate, setPasswordGenerate] = useState({
    token: '',
    address: ''
  });

  const [alertMessage, setAlertMessage] = useState({ message: '', type: '' });
  const [permission, setPermission] = useState(false);
  const [password, setPassword] = useState('');
  const [location, setLocation] = useState({ lat: '', lng: '' });
  const [unlocks, setUnlocks] = useState([]);
  const [empresa, setEmpresa] = useState('')
  const [textButton, setTextButton] = useState('Copiar Link do Certificado')
  const [showResults, setShowResults] = useState(false); // Estado para controlar a exibição dos resultados

  const handleShowResults = () => {
    setShowResults(true); // Exibe os resultados
  };

  useEffect(() => {
    const { permission } = JSON.parse(localStorage.getItem('userData'));
    setPermission(permission.equipments.write);
  }, []);

  useEffect(() => {
    (async () => {
      let config = {
        headers: {
          serialnumber: serials
        }
      };

      await axios(process.env.REACT_APP_API_MOTOMCO, getToken())
        .post(`/company/companybyserialnumber`, { serialnumber: serials })
        .then((response) => {
          var data = response.data;
          if (data) {
            setEmpresa(data);
          }
        });

      await axios(process.env.REACT_APP_API_GLOBAL_SERVER, getToken())
        .get(`/equipment/get`, config)
        .then((response) => {
          var data = response.data;
          data.daystoactivate = parseInt(data.daystoactivate, 16);
          setEquipments(data);
        });

      await axios(process.env.REACT_APP_API_GERA_SENHA, getToken())
        .get(`/log/unlock`, config)
        .then((response) => {
          let data = response.data;
          if (data == null) {
            return;
          }
          let date = dateformat(data.updatedAt);
          data.updatedAt = date;
          setUnlock(data);
        });

    })();
  }, []);

  useEffect(() => {
    (async () => {
      await axios(process.env.REACT_APP_API_GERA_SENHA, getToken())
        .post(`/log/allunlock`, { serialnumber: serials })
        .then((response) => {
          let data = response.data;
          if (data == null) {
            return;
          }
          setUnlocks(data);
        });
    })();
  }, []);

  const handleFieldChange = (event, field, value) => {
    event.persist && event.persist();
    //if (field === 'daystoactivate') value = value <= 1 ? 1 : value > 4095 ? 1 : value;
    setEquipments((values) => ({
      ...values,
      [field]: value
    }));
  };

  const handleFieldChangeUnlock = (event, field, value) => {
    event.persist && event.persist();
    setUnlock((values) => ({
      ...values,
      [field]: value
    }));
  };

  const handleFildChangePasswordGenerate = (event, field, value) => {
    event.persist && event.persist();
    setPasswordGenerate((values) => ({
      ...values,
      [field]: value
    }));
  };

  const handleSubmit = async () => {
    if (!equipments.daystoactivate) {
      setAlertMessage({
        message: 'O campo dias para Ativação deve ser preenchido!',
        type: 'error'
      });
      return;
    }

    let value = parseInt(equipments.daystoactivate);
    if (isNaN(value)) {
      setAlertMessage({
        message: 'O campo dias para Ativação deve ser preenchido com um número inteiro!',
        type: 'error'
      });
      return;
    }

    if (value < 0 || value > 4095) {
      setAlertMessage({
        message: 'O número de dias para Ativação deve estar entre 0 e 4095!',
        type: 'error'
      });
      return;
    }

    try {
      await axios(process.env.REACT_APP_API_GLOBAL_SERVER, getToken()).post(
        `/equipment/updatedaysactivation`,
        {
          serialnumber: serials,
          daystoactivate: Number(equipments.daystoactivate).toString(16).toUpperCase(),
          observation: equipments.observation
        }
      );
      setAlertMessage({ message: 'Atualizado com Sucesso!', type: 'success' });
    } catch (e) {
      setAlertMessage({ message: 'Falha ao atualizar!', type: 'error' });
    }
  };

  const handlePasswordGenerate = async () => {
    if (!passwordGenerate.token) {
      alert('Insira o token!');
      return;
    }

    if (!location.lat || !location.lng) {
      alert('Insira o endereço!');
      return;
    }

    let user = JSON.parse(localStorage.getItem('userData'));

    let json = {
      serialnumber: equipments.serialnumber,
      type: 'unblock',
      key: passwordGenerate.token,
      name: user.name,
      email: user.email,
      lat: location.lat,
      lng: location.lng
    };

    axios(process.env.REACT_APP_API_GERA_SENHA, getToken())
      .post('/password/unblock', json)
      .then((resp) => {
        setPassword(resp.data.code)
      });
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });

  const handlePlan = (newPlan) => {
    setEquipments((prevEquipments) => ({
      ...prevEquipments,
      plan: newPlan
    }));
    (async () => {
      await axios(process.env.REACT_APP_API_GLOBAL_SERVER, getToken()).post(
        `/equipment/updateplan`,
        {
          filter: [serials],
          plan: newPlan
        }
      )
    })()
  }

  const checkboxChange = (value) => {
    setEquipments((values) => ({
      ...values,
      ['mode']: value ? 'RENTAL' : 'OWNER'
    }));
    (async () => {
      await axios(process.env.REACT_APP_API_GLOBAL_SERVER, getToken()).post(
        `/equipment/updatemode`,
        {
          serialnumber: serials,
          mode: value ? 'RENTAL' : 'OWNER'
        }
      )
    })()
  }

  const checkboxIsentChange = (value) => {
    setEquipments((values) => ({
      ...values,
      ['isentkey']: value
    }));
    (async () => {
      await axios(process.env.REACT_APP_API_GLOBAL_SERVER, getToken()).post(
        '/equipment/update/isentkey',
        {
          serialnumber: equipments.serialnumber,
          isentkey: value
        }
      )
    })()
  }

  const handleCopyToClipboard = (link) => {
    copy(link);
    setTextButton('Link Copiado')
  }

  return (
    <Page className="p-3" title="Equipamentos">
      <Header page="Equipamentos" />

      <Card className='mb-2 mt-2 p-2'>
        <Row>
          <Col>
            <Button
              className='ms-2 mt-2 mb-2'
              onClick={() => { handleCopyToClipboard(`${process.env.REACT_APP_API_FILE_SERVER}/download/certificado/${equipments.serialnumber}-${equipments.cupid}`, "_blank") }}
              disabled={!equipments.dcal}
              variant="success"
            >
              {textButton}
            </Button>
          </Col>
        </Row>
      </Card>

      <Form
        permission={permission}
        alertMessage={alertMessage}
        equipments={equipments}
        onSubmit={handleSubmit}
        fieldChange={handleFieldChange}
        checkboxChange={checkboxChange}
        checkboxIsentChange={checkboxIsentChange}
        setAlertMessage={setAlertMessage}
        empresa={empresa}
        handlePlan={handlePlan}
      />

      <Form2
        alertMessage={alertMessage}
        unlock={unlock}
        fieldChange={handleFieldChangeUnlock}
      />

      <Form3
        permission={permission}
        onSubmit={handlePasswordGenerate}
        fieldChange={handleFildChangePasswordGenerate}
        password={password}
        setLocation={setLocation}
      />

      <Card>
        <Card.Header>
          <Button
            className="mt-2 mb-2"
            onClick={handleShowResults}
            variant="success"
          >
            Ver Resultados
          </Button>

          <Button
            className='mt-2 mb-2 ms-2'
            onClick={handlePrint}
            variant="outline-success"
            disabled={!showResults}
          >
            Imprimir
          </Button>
        </Card.Header>

        <Card.Body>
          <div ref={componentRef}>
            {showResults && equipments ? (
              <Results data={unlocks} />
            ) : (
              !showResults && (
                <p className="text-muted">Clique em "Ver Resultados" para visualizar os dados e imprimir.</p>
              )
            )}
          </div>
        </Card.Body>
      </Card>
    </Page>
  );
};

export default EquipmentsDetail;
