import React, { useState, useMemo, useCallback } from 'react';
import { Form, Dropdown, DropdownButton, FormControl, Spinner } from 'react-bootstrap';

const BootstrapMultiSelect = ({ hiddenOptions, label, options, selectedEquipments, setSelectedEquipments }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  // Memoiza as opções filtradas para evitar cálculos desnecessários
  const filteredOptions = useMemo(() => {
    return options.filter(option =>
      option.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [options, searchTerm]);

  // Função de seleção otimizada com useCallback para evitar recriação
  const handleSelect = useCallback((eventKey) => {
    setSelectedEquipments((prevSelected) =>
      prevSelected.includes(eventKey)
        ? prevSelected.filter(item => item !== eventKey)
        : [...prevSelected, eventKey]
    );
  }, [setSelectedEquipments]);

  // Debounce manual para otimizar a pesquisa e evitar execuções frequentes
  const handleSearch = useCallback((e) => {
    setSearchTerm(e.target.value);
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
    }, 300);
  }, []);

  // Simula o carregamento inicial de dados ao abrir o dropdown
  const handleToggle = useCallback((isOpen) => {
    setShowDropdown(isOpen);
    if (isOpen) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false); // Simula a conclusão do carregamento
      }, 100);
    }
  }, []);

  return (
    <div className="mt-1 mb-1">
      <Form.Group>
        <DropdownButton
          id="dropdown-multi-select"
          title={
            hiddenOptions ? label :
              selectedEquipments.length > 0
                ? selectedEquipments.map((item, index) => (
                  <div key={index} style={{ whiteSpace: 'normal' }}>{item}</div>
                ))
                : label
          }
          variant="success"
          show={showDropdown}
          onToggle={handleToggle}
        >
          <FormControl
            className='border border-secondary rounded'
            type="text"
            placeholder="Buscar..."
            value={searchTerm}
            onChange={handleSearch}
            style={{ marginTop: '0.5rem', marginLeft: '0.5rem', marginRight: '0.5rem', marginBottom: 0, width: '90%' }}
            onClick={(e) => e.stopPropagation()} // Evita fechar o dropdown ao clicar no campo de busca
          />
          <label style={{ marginLeft: '1rem', fontSize: '13px', color: '#1c4220' }}>
            Total: <strong>{filteredOptions.length}</strong>
          </label>
          <hr className="m-0" />
          <div style={{ maxHeight: '50vh', minWidth: '350px', overflowY: 'auto' }}>
            {loading ? (
              <div className="d-flex justify-content-center align-items-center pt-3 pb-2">
                <Spinner animation="border" variant="success" size="sm" />
                <span className="ms-2">Carregando...</span>
              </div>
            ) : filteredOptions.length > 0 ? (
              filteredOptions.map((option, index) => (
                <Dropdown.Item
                  key={index}
                  active={selectedEquipments.includes(option)}
                  onClick={(e) => {
                    e.stopPropagation(); // Impede o fechamento do dropdown
                    handleSelect(option);
                  }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.backgroundColor = '#157347';
                    e.currentTarget.style.color = 'white'; // Altera o texto para branco no hover
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.backgroundColor = selectedEquipments.includes(option) ? '#198754' : 'transparent';
                    e.currentTarget.style.color = selectedEquipments.includes(option) ? 'white' : 'black'; // Retorna à cor original
                  }}
                  style={{
                    backgroundColor: selectedEquipments.includes(option) ? '#198754' : 'transparent',
                    color: selectedEquipments.includes(option) ? 'white' : 'black',
                    cursor: 'pointer',
                    fontSize: '14px'
                  }}
                >
                  {option}
                </Dropdown.Item>
              ))
            ) : (
              <Dropdown.Item disabled>Nenhum item encontrado</Dropdown.Item>
            )}
          </div>
        </DropdownButton>
      </Form.Group>
    </div>
  );
};

export default BootstrapMultiSelect;
