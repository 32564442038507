import React, { useState } from 'react';
import { Card, Table, Pagination } from 'react-bootstrap';
import dateFormat from 'utils/dateformat';
import { FaSortUp, FaSortDown, FaSort } from 'react-icons/fa';

const Results = ({ data, permission }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [orderBy, setOrderBy] = useState('name'); // Campo padrão para ordenação
  const [order, setOrder] = useState('asc'); // Direção da ordenação (asc ou desc)

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(Number(event.target.value));
  };

  const totalPages = Math.ceil(data.length / rowsPerPage);
  const getPaginationRange = () => {
    const start = Math.max(page - 2, 0);
    const end = Math.min(page + 2, totalPages - 1);
    let range = [];

    for (let i = start; i <= end; i++) {
      range.push(i);
    }
    return range;
  };

  const formatCpfCnpj = (value) => {
    if (!value) return '--';

    const cleaned = value.replace(/\D/g, ''); // Remove caracteres não numéricos

    if (cleaned.length === 11) {
      // CPF: 123.456.789-00 → 123.***.***-00
      return `${cleaned.slice(0, 3)}.***.***-${cleaned.slice(-2)}`;
    } else if (cleaned.length === 14) {
      // CNPJ: 12.345.678/0001-00 → 12.***.***/0001-00
      return `${cleaned.slice(0, 2)}.***.***/${cleaned.slice(-6)}`;
    }

    return value; // Retorna o valor original caso não seja um CPF/CNPJ válido
  };

  const handleRowClick = (id) => {
    window.location.href = `/users/${id}`;
  };

  // Função para lidar com requisições de ordenação
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    setPage(0); // Volta para a primeira página ao ordenar
  };

  // Função para ordenar os dados
  const getSortedData = (data) => {
    const stabilizedThis = data.map((el, index) => [el, index]);
    
    stabilizedThis.sort((a, b) => {
      const itemA = a[0][orderBy] || '';
      const itemB = b[0][orderBy] || '';
      
      // Lidar com datas de forma especial
      if (orderBy === 'updatedAt') {
        const dateA = itemA ? new Date(itemA) : new Date(0);
        const dateB = itemB ? new Date(itemB) : new Date(0);
        return order === 'asc' ? dateA - dateB : dateB - dateA;
      }
      
      // Comparação normal para strings e números
      if (itemA < itemB) return order === 'asc' ? -1 : 1;
      if (itemA > itemB) return order === 'asc' ? 1 : -1;
      return a[1] - b[1]; // Mantém a ordem original para valores iguais
    });

    return stabilizedThis.map((el) => el[0]);
  };

  // Função para renderizar o ícone de ordenação
  const renderSortIcon = (column) => {
    if (column !== orderBy) {
      return <FaSort style={{ marginLeft: 5, opacity: 0.5 }} />;
    }
    return order === 'asc' ? 
      <FaSortUp style={{ marginLeft: 5, opacity: 1 }} /> : 
      <FaSortDown style={{ marginLeft: 5, opacity: 1 }} />;
  };

  // Aplicar ordenação antes da paginação
  const sortedData = getSortedData(data);
  const paginatedData = sortedData.slice(page * rowsPerPage, (page + 1) * rowsPerPage);

  return (
    <div>
      <p>
        {data.length} registros encontrados. Página {page + 1} de {totalPages}
      </p>
      <Card>
        <Card.Header>Usuários</Card.Header>
        <Card.Body>
          <Table style={{ fontSize: 15 }} striped bordered hover="true" responsive size='sm'>
            <thead>
              <tr className="table-secondary">
                <th 
                  className="text-center" 
                  onClick={() => handleRequestSort('name')}
                  style={{ cursor: 'pointer' }}
                >
                  NOME {renderSortIcon('name')}
                </th>
                <th 
                  className="text-center"
                  onClick={() => handleRequestSort('email')}
                  style={{ cursor: 'pointer' }}
                >
                  E-MAIL {renderSortIcon('email')}
                </th>
                <th 
                  className="text-center"
                  onClick={() => handleRequestSort('cpfcnpj')}
                  style={{ cursor: 'pointer' }}
                >
                  CPF/CNPJ {renderSortIcon('cpfcnpj')}
                </th>
                <th 
                  className="text-center"
                  onClick={() => handleRequestSort('fone')}
                  style={{ cursor: 'pointer' }}
                >
                  FONE {renderSortIcon('fone')}
                </th>
                <th 
                  className="text-center"
                  onClick={() => handleRequestSort('company')}
                  style={{ cursor: 'pointer' }}
                >
                  EMPRESA {renderSortIcon('company')}
                </th>
                <th 
                  className="text-center"
                  onClick={() => handleRequestSort('updatedAt')}
                  style={{ cursor: 'pointer' }}
                >
                  ÚLTIMO ACESSO {renderSortIcon('updatedAt')}
                </th>
              </tr>
            </thead>
            <tbody>
              {paginatedData.map((item) => (
                <tr
                  key={item._id}
                  className="clickable-row"
                  onClick={() => handleRowClick(item._id)}
                  style={{ cursor: 'pointer' }}
                  hover="true"
                  disabled={!permission}
                >
                  <td className="pt-2">{item.name || '--'}</td>
                  <td className="text-center pt-2 pb-2">{item.email || '--'}</td>
                  <td className="text-center pt-2 pb-2">{formatCpfCnpj(item.cpfcnpj) || '--'}</td>
                  <td className="text-center pt-2 pb-2">{item.fone || '--'}</td>
                  <td className="text-center pt-2 pb-2">{item.company || '--'}</td>
                  <td className="text-center pt-2 pb-2">{item.updatedAt ? dateFormat(item.updatedAt, "UTC:dd/mm/yy' 'HH:MM") : '--'}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
        <Card.Footer className="d-flex justify-content-between align-items-center">
          <div>
            <label>
              Linhas por página:{' '}
              <select
                value={rowsPerPage}
                onChange={handleChangeRowsPerPage}>
                {[10, 20, 40].map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </label>
          </div>
          <Pagination className="custom-pagination" variant="dark">
            <Pagination.Prev
              onClick={() => handleChangePage(page - 1)}
              disabled={page === 0}
              aria-label="Página anterior"
            />
            {getPaginationRange().map((index) => (
              <Pagination.Item
                key={index}
                active={index === page}
                onClick={() => handleChangePage(index)}
              >
                {index + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next
              onClick={() => handleChangePage(page + 1)}
              disabled={page >= totalPages - 1}
              aria-label="Próxima página"
            />
          </Pagination>
        </Card.Footer>
      </Card>
    </div>
  );
};

export default Results;
