import React, { useEffect, useRef, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Alert, Card, Row, Col, Button, Form, Table, Tab, Tabs } from 'react-bootstrap';
import { Page } from 'components';
import Terminal from '../Terminal';
import Logs from '../Logs';
import Header from '../../../../components/Header/Header';
import dateformat from 'utils/dateformat';
import { getToken } from 'actions';
import Api from 'services/Api';
import Graphic from '../Graphic';

const wsUrl = process.env.REACT_APP_API_MONITORA.replace('https', 'wss').replace('http', 'ws');
const refreshRate = 15000
let socket;

const Conected = (props) => {
  const history = useHistory();
  const requestRef = useRef(null);
  const serialNumber = useParams().id;

  const user = JSON.parse(localStorage.getItem('userData'));
  if (user?.permission?.supportdry?.read !== true) {
    window.location.replace('/home')
  }

  const [imageSrc, setImageSrc] = useState('');
  const [errors, setErrors] = useState([]);
  const [streamInternalCam, setStreamInternalCam] = useState(false);
  const [imageCamSrc, setImageCamSrc] = useState('');
  const [connectionStabished, setConnectionStabished] = useState(false)
  const [inputValueAlert, setInputValueAlert] = useState('');
  const [response, setResponse] = useState('Console\n');
  const [equipConfig, setEquipConfig] = useState({
    'serial': '--',
    'webcam': false,
    'freq_v': '--',
    'serv_e': '--',
    'serv_m': '--',
    'serv_d': '--',
    'calib_id': '--',
    'calib': '--',
    'eixo_x': '--',
    'eixo_y': '--',
    'eixo_z': '--',
    'v_firmware': '--',
    'v_firmware_osc': '--',
    'activation_date': '--',
    'activation_days': '--',
    'volume_corr_densidade': '--',
    'densidade_esfera': '--',
    'offset_bal1': '--',
    'offset_bal2': '--',
    'cross_check': '--',
    'sw': '--',
    'sws': '--'
  })

  const handleSubmit = (inputValue) => {
    if (!connectionStabished) return;

    const command = {
      type: 'message',
      room: serialNumber,
      content: {
        command: 'process',
        parameters: {
          command: inputValue
        }
      }
    };
    socket.send(JSON.stringify(command));
  };

  const handleSubmitAlert = (event) => {
    if (!inputValueAlert || !connectionStabished) return;

    event.preventDefault();
    const command = {
      type: 'alert',
      room: serialNumber,
      msg: inputValueAlert
    };
    socket.send(JSON.stringify(command));

    setInputValueAlert('');
  };

  const handleNewFrame = (imgData) => {
    requestRef.current = requestAnimationFrame(() => {
      setImageSrc(`data:image/png;base64,${imgData}`);
    });
  };

  const handleConnect = () => {
    let pingInterval
    // Criar uma nova instância WebSocket
    socket = new WebSocket(wsUrl);

    pingInterval = setInterval(() => {
      if (socket.readyState === WebSocket.OPEN) {
        socket.send(JSON.stringify({ type: 'ping' }));
      }
    }, 20000);

    socket.onopen = () => {
      setConnectionStabished(true);
    };

    socket.onmessage = (event) => {
      const data = event.data;
      let json_msg = JSON.parse(data);

      if (json_msg.type === "invalid_user") {
        if (json_msg?.msg) {
          alert(json_msg?.msg);
        } else {
          alert("Sem permissão!");
        }
        handleDisconnect();
      }

      if (json_msg.type === "valid_user") {
        setConnectionStabished(true)
        handleLoadData()
      }

      if (json_msg.type === "desconnect") {
        setConnectionStabished(false)
      }

      if (json_msg.type === "connected") {
        console.log('connected');

        setConnectionStabished(true)
        handleLoadData()
      }

      if (json_msg.pubkey) {

        socket.send(
          JSON.stringify({
            type: 'join',
            room: serialNumber,
            user_id: user?._id,
            name: user?.name,
            email: user?.email,
            client: 'client',
            token: getToken(),
            content: {
              name: `${user.email}-API-SUPORTE-DRY`
            },
          }));

      } else {
        if (json_msg?.content?.img) {
          handleNewFrame(json_msg.content.img);
          return;
        }

        if (json_msg?.content?.text) {
          requestAnimationFrame(() => {
            setResponse(json_msg.content.text);
          });
          return;
        }

        if (json_msg?.content?.img_cam) {
          requestAnimationFrame(() => {
            setImageCamSrc(`data:image/png;base64,${json_msg.content.img_cam}`);
          });
          return;
        }

        if (json_msg?.content?.network_config) {
          // setNetworkConfig(json_msg.content.network_config);
          return;
        }

        if (json_msg?.content?.equip_config) {
          requestAnimationFrame(() => {
            setEquipConfig(json_msg.content.equip_config);
          });
          return;
        }
      }
    };

    socket.onclose = () => {
      clearInterval(pingInterval)
    };
  };

  const handleLoadData = () => {
    socket.send(
      JSON.stringify({
        type: 'message',
        room: serialNumber,
        content: {
          command: 'load_data',
          parameters: {}
        },
      })
    );

    socket.send(
      JSON.stringify({
        type: 'connect_support',
        room: serialNumber,
        hidden: user.master ? user.master : false
      })
    );
  }

  const handleImageClick = (event) => {
    if (!connectionStabished) return;

    const rect = event.target.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    socket.send(
      JSON.stringify({
        type: 'message',
        room: serialNumber,
        content: {
          command: 'click',
          parameters: {
            x: x,
            y: y
          }
        }
      })
    );
  };

  const sendGetScreen = () => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      socket.send(
        JSON.stringify({
          type: 'connect_support',
          room: serialNumber,
          stream_internal_cam: streamInternalCam,
          hidden: user.master ? user.master : false
        })
      );
    }
  };

  useEffect(() => {
    const interval1 = setInterval(() => {
      sendGetScreen();
    }, refreshRate);
    return () => {
      clearInterval(interval1);
    };
  }, [socket, streamInternalCam]);

  // Função para desconectar o WebSocket
  const handleDisconnect = () => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      socket.send(
        JSON.stringify({
          type: 'desconnect_support',
          room: serialNumber
        })
      );
      socket.close();
      setConnectionStabished(false);
    }
    history.push('/supportdry');
  };

  const handleGetErrors = async () => {
    try {
      const { data } = await Api.get('/troubles/list-equipment', { params: { serial: serialNumber } });
      setErrors(data);
    } catch (error) {
      console.log(error?.message);
    }
  }

  useEffect(() => {
    handleGetErrors(); // Chamada inicial

    const interval = setInterval(() => {
      handleGetErrors();
    }, 20000); // 20 segundos

    return () => clearInterval(interval); // Limpa o intervalo ao desmontar o componente
  }, []);

  useEffect(() => {
    handleConnect();
    return () => {
      if (socket) socket.close();
    };
  }, []);

  return (
    <Page className="p-3" title="Suporte Dry">
      <Header page={"Suporte Dry"} handleDisconnect={handleDisconnect} />

      <Card className='mb-2 mt-2 p-2'>
        <Row className='g-2'>
          <Col>
            {connectionStabished ?
              <Alert variant="success" className='ms-1 me-1 mt-2 mb-2 p-2'>
                <p style={{ marginBottom: 0 }}>
                  Conectado
                </p>
              </Alert>
              :
              <Alert variant="danger" className='ms-1 me-1 mt-2 mb-2 p-2'>
                <p style={{ marginBottom: 0 }}>
                  Desconectado
                </p>
              </Alert>
            }
          </Col>
          <Col className='d-flex justify-content-end align-items-center'>
            <Button
              variant="outline-danger"
              size="sm"
              className='ms-1 me-1 mt-1'
              onClick={() => { handleDisconnect() }}
            >
              Desconectar
            </Button>
          </Col>
        </Row>
      </Card>

      <Card className='mb-2 p-2'>
        <Row className='g-2'>
          <Col>
            <div>
              {!imageSrc ? (
                <span style={{ color: 'black' }}>
                  Aguardando imagem...
                </span>
              ) : (
                <img
                  onClick={handleImageClick}
                  src={imageSrc}
                  style={{
                    width: '800px',
                    height: '480px',
                    objectFit: 'cover',
                    cursor: connectionStabished ? 'pointer' : 'none',
                    opacity: connectionStabished ? 1 : 0.7,
                    filter: connectionStabished ? 'none' : 'grayscale(100%)',
                    borderRadius: '8px', // Arredondar a borda da imagem
                    border: '8px solid grey', // Contorno sutil da imagem
                  }}
                />
              )}
            </div>
          </Col>

          <Col>
            <div style={{ height: 480, maxWidth: 800 }}>
              <Tabs defaultActiveKey="1" id="fill-tab-example" fill>

                {/* Aba do Terminal */}
                <Tab eventKey="1" title="Terminal">
                  <div style={{ height: 440, overflowY: "auto" }}>
                    <Terminal
                      onCommandSubmit={handleSubmit}
                      response={response}
                      connectionStabished={connectionStabished}
                      user={user}
                    />
                  </div>
                </Tab>

                {/* Aba dos Logs */}
                <Tab eventKey="2" title="Logs">
                  <div style={{ height: 440, overflowY: "auto" }}>
                    <Logs serialNumber={serialNumber} data={errors} />
                  </div>
                </Tab>

                {/* Aba dos Logs */}
                <Tab eventKey="3" title="Estatística">
                  <div style={{ height: 440, overflowY: "auto" }}>
                    <Graphic serialNumber={serialNumber} data={errors} />
                  </div>
                </Tab>

              </Tabs>
            </div>
          </Col>
        </Row>
      </Card>

      <Row className='g-2'>
        <Col md={6} className='d-flex flex-column'>
          <div className="p-2 bg-light border flex-grow-1 d-flex justify-content-center align-items-center">
            <Table style={{ fontSize: "0.9rem" }} striped bordered hover="true" responsive size='sm'>
              <tbody>
                <tr className="table-secondary">
                  <th className="text-center" scope="col">SERIAL</th>
                  <th className="text-center" scope="col">FREQUÊNCIA (V)</th>
                  <th className="text-center" scope="col">ID CALIBRAÇÃO</th>
                  <th className="text-center" scope="col">WEBCAM</th>
                </tr>
                <tr>
                  <td className="text-center">{equipConfig.serial}</td>
                  <td className="text-center">{equipConfig.freq_v}</td>
                  <td className="text-center">{equipConfig.calib_id}</td>
                  <td className="text-center">{equipConfig.webcam ? "Sim" : "Não"}</td>
                </tr>
                <tr className="table-secondary">
                  <th className="text-center" scope="col">DATA DE ATIVAÇÃO</th>
                  <th className="text-center" scope="col">DIAS DE ATIVAÇÃO</th>
                  <th className="text-center" scope="col">SOFTWARE</th>
                  <th className="text-center" scope="col">SOFTWARE BACKUP</th>
                </tr>
                <tr>
                  <td className="text-center">{equipConfig.activation_date ? dateformat(equipConfig.activation_date, true) : ''}</td>
                  <td className="text-center">{equipConfig.activation_days}</td>
                  <td className="text-center">{equipConfig.sw}</td>
                  <td className="text-center">{equipConfig.sws}</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Col>

        <Col md={3} className='d-flex flex-column'>
          <div className="p-2 bg-light border flex-grow-1">
            <Row className="d-flex flex-column align-items-center">
              <Col className="w-100 mb-2">
                <Form.Control
                  onChange={(e) => setInputValueAlert(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleSubmitAlert(e);
                    }
                  }}
                  type="text"
                  placeholder="Enviar alerta ao equipamento..."
                  value={inputValueAlert}
                  className="w-100"
                  disabled={!(user?.permission?.supportdry?.write && connectionStabished)}
                />
              </Col>

              <Col className="w-100">
                <Button
                  variant="success"
                  onClick={handleSubmitAlert}
                  type="button"
                  className="w-100"
                  disabled={!(user?.permission?.supportdry?.write && connectionStabished)}
                >
                  Enviar
                </Button>
              </Col>
            </Row>
          </div>
        </Col>

        <Col md={3} className='d-flex flex-column'>
          <div className="p-2 bg-light border flex-grow-1">
            <Row>
              <Col
                className='m-0'
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '220px'
                }}
              >
                {!streamInternalCam ? (
                  <Button
                    variant="success"
                    onClick={() => {
                      setStreamInternalCam(true);
                    }}
                    className="w-100"
                    disabled={connectionStabished && equipConfig.webcam ? false : true}
                  >
                    Abrir Câmera Interna do Equipamento
                  </Button>
                ) : imageCamSrc ? (
                  <img
                    alt="Screenshot"
                    src={imageCamSrc}
                    className="w-100"
                  />
                ) : (
                  <div
                    style={{
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      textAlign: 'center',
                    }}
                  >
                    <h6 style={{ color: 'black' }}>Aguardando Imagem...</h6>
                  </div>
                )}
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

    </Page>
  );
};

export default Conected;
