import React, { useRef, useState, useEffect } from 'react';
import { Button, Form, Card, InputGroup, Row, Col, FloatingLabel, Modal } from 'react-bootstrap';
import { Page } from 'components';
import { MdClose } from "react-icons/md";
import Header from '../../components/Header/Header';
import NotifyModal from '../../components/NotifyModal/NotifyModal';
import Reload from 'components/Reload/Reload';
import './EquipmentsReplace.css';

import { TbReplace } from "react-icons/tb";

import { BrowserMultiFormatReader, DecodeHintType, BarcodeFormat } from '@zxing/library';
import ApiMServer from 'services/ApiMServer';

const EquipmentsReplace = () => {
  const videoRef = useRef(null);

  const user = JSON.parse(localStorage.getItem('userData')) || null;
  if (user?.permission?.equipmentsreplace?.read !== true) {
    window.location.replace('/home');
  }

  const { permission } = user;

  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [oldEquipment, setOldEquipment] = useState('');
  const [newEquipment, setNewEquipment] = useState('');
  const [scanner, setScanner] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [company, setCompany] = useState({});

  const [msg, setMsg] = useState('');

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const handleCloseAlert = () => setShowAlert(false);
  const handleCloseAlertSuccess = () => setShowSuccess(false);

  const [type, setType] = useState({ color: "", status: "" });
  const [modalShow, setModalShow] = useState(false);

  const handleAlertModalSuccess = (msg) => {
    setType({ color: "#198754", status: msg })
    setModalShow(true)
  }

  const handleAlertModalError = (msg) => {
    setType({ color: "red", status: msg })
    setModalShow(true)
  }

  useEffect(() => {
    const codeReader = new BrowserMultiFormatReader();

    const hints = new Map();
    hints.set(DecodeHintType.POSSIBLE_FORMATS, Object.values(BarcodeFormat));

    codeReader.hints = hints; // Define os hints no leitor

    setScanner(codeReader);
    setLoading(false);

    return () => {
      codeReader.reset();
      setScanner(null);
    };
  }, []);

  const startCamera = async (input) => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: "environment" },
      });

      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.play();
        console.log("Câmera iniciada com sucesso.");
      }

      scanner.decodeFromVideoDevice(null, videoRef.current, (result, error) => {
        if (result) {
          handleDecodedResult(result, input);
        } else if (error) {
          console.log("Esperando QR Code:");
        }
      });
    } catch (error) {
      console.error("Erro ao acessar a câmera:", error);
      alert("Não foi possível acessar a câmera. Verifique as permissões e tente novamente.");
    }
  };

  const handleDecodedResult = (result, input) => {
    try {
      const format = result.getBarcodeFormat();
      const text = result.getText();

      console.log("Formato detectado:", format, "Conteúdo:", text);

      if (input === "newEquipment" && format !== 11) {
        setMsg("Leia o QR Code da tela do equipamento novo!");
        setShowAlert(true);
        handleStopClick(); // Para a câmera após obter o valor
        return;
      }

      if (format === 4) {
        updateEquipment(input, text);
      } else {
        const jsonData = JSON.parse(text);
        if (jsonData?.serial) {
          updateEquipment(input, jsonData.serial);
        } else {
          throw new Error("O JSON não contém o campo 'serial'.");
        }
      }
    } catch (error) {
      console.error("Erro ao processar o QR Code:", error.message);
    }
  };

  const updateEquipment = (input, value) => {
    if (input === "oldEquipment") {
      setOldEquipment(value);
    } else if (input === "newEquipment") {
      setNewEquipment(value);
    }
    handleStopClick(); // Para a câmera após obter o valor
  };

  const stopCamera = () => {
    const stream = videoRef.current?.srcObject;
    if (stream) {
      const tracks = stream.getTracks();
      tracks.forEach((track) => track.stop());
    }
    setIsCameraOpen(false);
  };

  const handleScanClick = (input) => {
    setIsCameraOpen(true);
    startCamera(input);
  };

  const handleStopClick = () => {
    stopCamera();
  };

  const handleConfirm = async () => {
    if (!oldEquipment || !newEquipment) {
      setMsg("Os dois campos devem ser preenchidos antes de continuar!");
      setShowAlert(true);
    } else if (oldEquipment === newEquipment) {
      setMsg("Número de serie iguais!");
      setShowAlert(true);
    } else {
      handleOpenModal();
    }
  }

  const handleReplaceEquipment = async () => {
    handleCloseModal();
    setLoading(true);
    try {
      const { data } = await ApiMServer.post("/company/replace-equipment", { oldEquipment, newEquipment });
      setCompany(data);
      setNewEquipment('');
      setOldEquipment('');
      //handleAlertModalSuccess(`Troca realizada com successo para a empresa: ${data.name || "--"}`);
      setShowSuccess(true);
    } catch (error) {
      setMsg(error.response?.data?.status || "Erro ao atualizar!");
      setShowAlert(true);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Page className="p-3" title="Troca de Equipamentos">
      <Header page={"Troca de Equipamentos"} />

      {loading && <Reload />}

      <NotifyModal
        type={type}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />

      <Card className='mb-3 mt-2 p-3'>
        <Row>
          <Col>
            <form>
              <div className="mb-3">
                <InputGroup>
                  <FloatingLabel
                    controlId="floatingInput1"
                    label="Equipamento"
                    className="flex-grow-1"
                  >
                    <Form.Control
                      className={'border-secondary'}
                      type="text"
                      name="oldEquipment"
                      placeholder="Equipamento"
                      value={oldEquipment}
                      onChange={(e) => setOldEquipment(e.target.value.toUpperCase())}
                      disabled={oldEquipment?.length >= 10 ? true : false}
                    //disabled={true}
                    />
                  </FloatingLabel>
                  {oldEquipment?.length >= 10 ?
                    <Button
                      onClick={() => setOldEquipment('')}
                      variant="secondary"
                      className="ms-2 min-width-button"
                    >
                      <MdClose size={20} />
                    </Button>
                    :
                    <Button
                      onClick={() => handleScanClick('oldEquipment')}
                      variant="success"
                      className="ms-2 min-width-button"
                      disabled={!permission?.equipmentsreplace?.write}
                    >
                      Escanear QR
                    </Button>
                  }
                </InputGroup>
              </div>

              <div className="mt-3 mb-3">
                <InputGroup>
                  <FloatingLabel
                    controlId="floatingInput2"
                    label="Equipamento Novo"
                    className="flex-grow-1"
                  >
                    <Form.Control
                      className={'border-secondary'}
                      type="text"
                      name="newEquipment"
                      placeholder="Equipamento Novo"
                      value={newEquipment}
                      onChange={(e) => setNewEquipment(e.target.value.toUpperCase())}
                      //disabled={newEquipment?.length >= 10 ? true : false}
                      disabled={true}
                    />
                  </FloatingLabel>
                  {newEquipment?.length >= 10 ?
                    <Button
                      onClick={() => setNewEquipment('')}
                      variant="secondary"
                      className="ms-2 min-width-button"
                    >
                      <MdClose size={20} />
                    </Button>
                    :
                    <Button
                      onClick={() => handleScanClick('newEquipment')}
                      variant="success"
                      className="ms-2 min-width-button"
                      disabled={!permission?.equipmentsreplace?.write}
                    >
                      Escanear QR
                    </Button>
                  }
                </InputGroup>
              </div>
            </form>
          </Col>
        </Row>

        {isCameraOpen && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100vw",
              height: "100vh",
              backgroundColor: "rgba(0, 0, 0, 0.9)",
              zIndex: 9999,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <video
              ref={videoRef}
              className="rounded"
              style={{
                width: "100%",
                maxWidth: "800px",
                height: "auto",
                border: "2px solid grey",
                backgroundColor: "#000",
              }}
            />
            <Button
              onClick={handleStopClick}
              variant="outline-danger"
              className="mt-3"
            >
              Fechar Câmera
            </Button>
          </div>
        )}

        <Button
          size='lg'
          className="mt-3"
          variant="success"
          onClick={() => {
            handleConfirm()
          }}
          disabled={!permission?.equipmentsreplace?.write}
        >
          Confirmar Troca <TbReplace size={20} />
        </Button>

      </Card>

      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirmar Troca de Equipamentos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Confirma a troca dos equipamentos?</p>
          <div className="p-2 bg-light rounded">
            <p><strong>Equipamento Antigo:</strong> {oldEquipment || "Não informado"}</p>
            <p><strong>Equipamento Novo:</strong> {newEquipment || "Não informado"}</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancelar
          </Button>
          <Button variant="success" onClick={handleReplaceEquipment}>
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showAlert} onHide={handleCloseAlert} centered>
        <Modal.Header closeButton>
          <Modal.Title>Aviso</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{msg}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseAlert}>
            Entendido
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showSuccess} onHide={handleCloseAlertSuccess} centered>
        <Modal.Header closeButton className="bg-success text-white">
          <Modal.Title>
            Troca Concluída
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="p-2 bg-light rounded">
            <p>Troca realizada com sucesso!</p>
            <p>Empresa: <strong>{company.name || "--"}</strong></p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleCloseAlertSuccess}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>

    </Page>
  );
};

export default EquipmentsReplace;
